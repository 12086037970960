import React from "react";
import Card from "./Card";
import { Grid, SvgIcon } from "@mui/material";
import { ReactComponent as BookSlotIcon } from "../../Assets/Icons/BookSlotIcon.svg";
import Calendar from "./Calendar";

const BookSlot = (props) => {

  return (
    <Card hasBorder={true} borderPosition={"top"} style={{paddingRight:"40px,20px,40,40px"}}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={0.25} md={0.25} lg={0.25}>
              <SvgIcon component={BookSlotIcon} />
            </Grid>
            <Grid
              item
              xs={11.5}
              md={11.5}
              lg={11.5}
              sx={{ paddingLeft: "41px", fontWeight: 700, color: "#000" }}
            >
              {"Book Your Slot"}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            justifyContent={"left"}
            alignItems={"left"}
            textAlign={"left"}
          >
            <Calendar
            {...props}
            // days={deliveryDays}
             />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BookSlot;
