import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ProductCard from '../../Components/ProductCard';
import { useStyles } from "./style";
import Carousel, { consts } from 'react-elastic-carousel';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SwipeableCarousel from "./SwipeableCarousel";

function myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? <KeyboardArrowLeftIcon fontSize="small" color="red" /> : <KeyboardArrowRightIcon />
    return (
        <button
            style={{
                backgroundColor: 'transparent', border: 'none', width: '20px', borderRadius: '50%',
                textAlign: 'center', display: 'flex',
                justifyContent: 'center', alignItems: 'center'
            }}
            onClick={onClick} disabled={isEdge}>
            {pointer}
        </button>
    )
}


const SubCategory = (props) => {
    const classes = useStyles();
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 2, itemsToShow: 2 },
        { width: 550, itemsToShow: 4 },
        { width: 768, itemsToShow: 4 },
        { width: 900, itemsToShow: 5 },
        { width: 1100, itemsToShow: 5 },
    ];
    const setCategoryId = (id) => {
        localStorage.setItem("categoryid", id);
    };
    const routeToCategory1 = () => {
        props.navigate('/stores/categories/category1');
    };
    const routeToCategoryProducts = () => {
        props.navigate(`/stores/categories/categoryproducts`);
    };
    React.useEffect(() => {
        window.scrollTo(0, 0,);
        let sid = localStorage.getItem("storeid");
        let categoryid = localStorage.getItem("departmentid");
        let userid = localStorage.getItem("userid");
        props.props.getCategorylist({ storeid: sid, categoryid: categoryid, userid: userid });
    },
        // eslint-disable-next-line
        [])

    return (
        <Box flex={4} sx={{ paddingTop: '25px' }}>
            <Grid container spacing={2} sx={{ flexGrow: 0.5 }}>
                {props.props.isStoreDetailsLoading && props.props.isStoreDetailsLoading === true ?
                    'Loading' :
                    (props.props.isStoreDetailsSuccess && props.props.isStoreDetailsSuccess === true &&
                        props.props.StoreDetails &&
                        props.props.StoreDetails !== null &&
                        props.props.StoreDetails.storeStatus === "active" && props.props.StoreDetails.storeWorking === true) ?
                (props.props.isCategoriesLoading && props.props.isCategoriesLoading === true ?
                    'Loading' :
                    props.props.isCategories && props.props.isCategories === true &&
                        props.props.Categories && props.props.Categories !== null && props.props.Categories.length > 0 ?
                        props.props.Categories.map(({ categoryProducts, name, id, storeid, childCategoryPresent }) =>
                            categoryProducts.items.length > 0 &&
                            <React.Fragment key={id}>
                                <Grid item xs={8} md={10} className={classes.departmentname}>
                                    {name}
                                </Grid>
                                <Grid item xs={4} md={2} className={classes.viewallgrid}>
                                    {childCategoryPresent === false ? (
                                        <span
                                            onClick={() => {
                                                setCategoryId(id);
                                                routeToCategoryProducts();
                                            }}
                                            className={classes.viewall}
                                        >View all</span>
                                    )
                                        : (
                                            <span
                                                onClick={() => {
                                                    setCategoryId(id);
                                                    routeToCategory1()
                                                }}
                                                className={classes.viewall}
                                            >View all</span>
                                        )}
                                </Grid>
                                {fullScreen ?
                                    <SwipeableCarousel {...props}>
                                        {categoryProducts.items.slice(0, 5).map(item =>
                                            <Grid item >
                                                <div style={{ padding: '5px', paddingBottom: '25px', justifyContent: 'space-around' }}>
                                                    <ProductCard
                                                        id={item.id}
                                                        productname={item.productname && item.productname}
                                                        image={item.image && item.image.primary && item.image.primary}
                                                        price={item.price && item.price}
                                                        markedupProductPrice={item?.markedupProductPrice}
                                                        promoprice={item.promoprice && item.promoprice}
                                                        storeid={storeid}
                                                        storename={localStorage.getItem('storeName')}
                                                        uom={item.uom && item.uom}
                                                        quantity={item.quantity}
                                                        bxgy={item.bxgy}
                                                        storeoffline={false}
                                                        {...props} />
                                                </div>
                                            </Grid>
                                        )}
                                    </SwipeableCarousel> :
                                    <Carousel
                                        renderArrow={myArrow}
                                        itemPosition="START"
                                        breakPoints={breakPoints}
                                        showEmptySlots={true}
                                        itemPadding={[0]}
                                        outerSpacing={0}
                                        pagination={false}>
                                        {categoryProducts.items.slice(0, 5).map(item =>
                                            <Grid item >
                                                <div style={{ padding: '5px', paddingBottom: '25px', justifyContent: 'space-around' }}>
                                                    <ProductCard
                                                        id={item.id}
                                                        productname={item.productname && item.productname}
                                                        image={item.image && item.image.primary && item.image.primary}
                                                        price={item.price && item.price}
                                                        markedupProductPrice={item?.markedupProductPrice}
                                                        promoprice={item.promoprice && item.promoprice}
                                                        storeid={storeid}
                                                        storename={localStorage.getItem('storeName')}
                                                        uom={item.uom && item.uom}
                                                        quantity={item.quantity}
                                                        bxgy={item.bxgy}
                                                        storeoffline={false}
                                                        {...props} />
                                                </div>
                                            </Grid>
                                        )}
                                    </Carousel>
                                }
                            </React.Fragment>
                        )
                        : props.props.isCategoriesFailed && props.props.isCategoriesFailed === true &&
                        'OOPS'
                ):(
                    props.props.isCategoriesLoading && props.props.isCategoriesLoading === true ?
                    'Loading' :
                    props.props.isCategories && props.props.isCategories === true &&
                        props.props.Categories && props.props.Categories !== null && props.props.Categories.length > 0 ?
                        props.props.Categories.map(({ categoryProducts, name, id, storeid, childCategoryPresent }) =>
                            categoryProducts.items.length > 0 &&
                            <React.Fragment key={id}>
                                <Grid item xs={8} md={10} className={classes.departmentname}>
                                    {name}
                                </Grid>
                                <Grid item xs={4} md={2} className={classes.viewallgrid}>
                                    {childCategoryPresent === false ? (
                                        <span
                                            onClick={() => {
                                                setCategoryId(id);
                                                routeToCategoryProducts();
                                            }}
                                            className={classes.viewall}
                                        >View all</span>
                                    )
                                        : (
                                            <span
                                                onClick={() => {
                                                    setCategoryId(id);
                                                    routeToCategory1()
                                                }}
                                                className={classes.viewall}
                                            >View all</span>
                                        )}
                                </Grid>
                                {fullScreen ?
                                    <SwipeableCarousel {...props}>
                                        {categoryProducts.items.slice(0, 5).map(item =>
                                            <Grid item >
                                                <div style={{ padding: '5px', paddingBottom: '25px', justifyContent: 'space-around' }}>
                                                    <ProductCard
                                                        id={item.id}
                                                        productname={item.productname && item.productname}
                                                        image={item.image && item.image.primary && item.image.primary}
                                                        price={item.price && item.price}
                                                        markedupProductPrice={item?.markedupProductPrice}
                                                        promoprice={item.promoprice && item.promoprice}
                                                        storeid={storeid}
                                                        storename={localStorage.getItem('storeName')}
                                                        uom={item.uom && item.uom}
                                                        quantity={item.quantity}
                                                        bxgy={item.bxgy}
                                                        storeoffline={true}
                                                        {...props} />
                                                </div>
                                            </Grid>
                                        )}
                                    </SwipeableCarousel> :
                                    <Carousel
                                        renderArrow={myArrow}
                                        itemPosition="START"
                                        breakPoints={breakPoints}
                                        showEmptySlots={true}
                                        itemPadding={[0]}
                                        outerSpacing={0}
                                        pagination={false}>
                                        {categoryProducts.items.slice(0, 5).map(item =>
                                            <Grid item >
                                                <div style={{ padding: '5px', paddingBottom: '25px', justifyContent: 'space-around' }}>
                                                    <ProductCard
                                                        id={item.id}
                                                        productname={item.productname && item.productname}
                                                        image={item.image && item.image.primary && item.image.primary}
                                                        price={item.price && item.price}
                                                        markedupProductPrice={item?.markedupProductPrice}
                                                        promoprice={item.promoprice && item.promoprice}
                                                        storeid={storeid}
                                                        storename={localStorage.getItem('storeName')}
                                                        uom={item.uom && item.uom}
                                                        quantity={item.quantity}
                                                        bxgy={item.bxgy}
                                                        storeoffline={true}
                                                        {...props} />
                                                </div>
                                            </Grid>
                                        )}
                                    </Carousel>
                                }
                            </React.Fragment>
                        ):
                        props.props.isCategoriesFailed && props.props.isCategoriesFailed === true &&
                        'OOPS'
                        )}
            </Grid>
        </Box>
    );
};

export default SubCategory;