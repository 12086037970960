import { connect } from "react-redux";
import { SelectState } from "./Selector";
import {
  getLogin,
  getSignup,
  sendloginOTP,
  verifyOTP,
  mobilesignupAccess,
  resetpassword,
  resetForgotPasswordProps,
  resetMobileSigninProps,
  getPlaceBylatlng,
  setCartlimit,
  resetsuggestions,
  getCurrentlocation
} from "../Redux/Actions/LoginAction";
import {
  getStores,
  removeLocationProps,
  getBusinessTypes,
  addStoretoVisited,
  getAddress,
  autocomplete,
  getUserDetails,
  getActiveOrders,
  getActiveorderLength,
  getCompletedOrders,
  getCart,
  getDepartments,
  getAvailableAddresses,
  getCheckoutDetails,
  getDeliveryCharge,
  addtocart,
  updatecart,
  removeproduct,
  setCart,
  getCategorylist,
  getCategory1list,
  getCategory2list,
  getDepartmentProducts,
  getCategoryProducts,
  getCategory1Products,
  getCategory2Products,
  getProductDetails,
  setCheckoutAddress,
  addPlaceorderPhoneNumber,
  setCheckoutSlot,
  getCheckoutPhoneNumber,
  sendotp,
  verifyotp,
  placeOrder,
  getLiveOrders,
  setUserLocation,
  updateprimarylocation,
  resetUpdate,
  suggestioncordinates,
  addaddress,
  changeaddress,
  setAddresscordinates,
  clearAddresscordinates,
  setEditAddressFields,
  clearAddAddressProps,
  clearDeleteAddressProps,
  clearEditAddressProps,
  deleteaddress,
  changeaccount,
  searchproducts,
  Sendotp,
  resetCheckoutProps,
  filterStores,
  clearcart,
  clearstorefromcart,
  closesnackbars,
  ReferFriend,
  updatepassword,
  addemail,
  getOrderdetails,
  clearPasswordProps,
  updatecartproduct,
  createcartreset,
  setCheckoutSlotDateSelection,
  setCheckoutInstructions,
  getLiveOrdersLength,
  setCheckoutDayIndex,
  setRating,
  addrating,
  deleteaccount,
  clearSigninProps,
  getAvailableCouponsList,
  setCheckoutCoupons,
  applyCoupon,
  removeCoupon,
  setCheckoutSlotinit,
  getStoreDetails,
  otpclose,
  getcomplaintslist,
  getPreviousComplaints,
  getSubcomplaintslist,
  getOrderItemslist,
  SelectedItemList,
  SubmitItemList,
  selectItemImage,
  selectOption,
  RegisterComplaint,
  clearComplaintsProps,
  resetComplaintProps,
  addpassword,
  clearProductSearchProps,
  updatefullcart,
  upateAddress,
  adddeliveryTip,
  quantityChanges,
  ApplyBestCoupon,
  addCookingInstructions,
  adddeliveryInstructions,
  setdateAndTimeForDelivery,
  getPopularProducts,
  serachProductsGlobally,
  getProductSearch,
  removeAppliedCoupon,
  updateRecentSearch,
  setPaymentType,

} from "../Redux/Actions/SigninAction";
import RouterFile from "./RouterFile";

const mapStateToProps = (state) => {
  return { ...SelectState(state) };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearProductSearchProps: () => {
      dispatch(clearProductSearchProps());
    },
    resetsuggestions: () => {
      dispatch(resetsuggestions());
    },
    getStoreDetails: (params,params1) => {
      dispatch(getStoreDetails(params,params1));
    },
    otpclose: () => {
      dispatch(otpclose());
    },
    setCartlimit: () => {
      dispatch(setCartlimit());
    },
    getPlaceBylatlng: (params) => {
      dispatch(getPlaceBylatlng(params));
    },
    getCurrentlocation: (params) => {
      dispatch(getCurrentlocation(params));
    },
    removeLocationProps: () => {
      dispatch(removeLocationProps());
    },
    resetMobileSigninProps: () => {
      dispatch(resetMobileSigninProps());
    },
    getLogin: (params) => {
      dispatch(getLogin(params));
    },
    getSignup: (params) => {
      dispatch(getSignup(params));
    },
    closesnackbars: () => {
      dispatch(closesnackbars());
    },
    sendloginOTP: (params) => {
      dispatch(sendloginOTP(params));
    },
    verifyOTP: (params1, params2) => {
      dispatch(verifyOTP(params1, params2));
    },
    mobilesignupClick: (params1, params2, params3, params4, params5, params6) => {
      dispatch(mobilesignupAccess(params1, params2, params3, params4, params5, params6));
    },
    resetpassword: (params) => {
      dispatch(resetpassword(params));
    },
    resetForgotPasswordProps: (params) => {
      dispatch(resetForgotPasswordProps(params));
    },
    setRating: ({ e, params }) => {
      dispatch(setRating({ e, params }));
    },
    addrating: (params1, params2, params3, params4) => {
      dispatch(addrating(params1, params2, params3, params4));
    },
    setCheckoutDayIndex: (params1, params2) => {
      dispatch(setCheckoutDayIndex(params1, params2));
    },
    getLiveOrdersLength: (params) => {
      dispatch(getLiveOrdersLength(params));
    },
    setCheckoutInstructions: (params) => {
      dispatch(setCheckoutInstructions(params))
    },
    setCheckoutSlotDateSelection: (params1, params2) => {
      dispatch(setCheckoutSlotDateSelection(params1, params2))
    },
    setCheckoutSlotinit: (params) => {
      dispatch(setCheckoutSlotinit(params))
    },
    createcartreset: () => {
      dispatch(createcartreset());
    },
    addemail: (userid, email) => {
      dispatch(addemail(userid, email));
    },
    updatepassword: (userid, password, newpassword) => {
      dispatch(updatepassword(userid, password, newpassword));
    },
    addpassword:(userid,password) => {
      dispatch(addpassword(userid,password));
    },
    clearPasswordProps: () => {
      dispatch(clearPasswordProps());
    },
    updatecartproduct: (params1, params2, params3, params4) => {
      dispatch(updatecartproduct(params1, params2, params3, params4));
    },
    getStores: (lat, lng) => {
      dispatch(getStores(lat, lng));
    },
    getBusinessTypes: () => {
      dispatch(getBusinessTypes());
    },
    filterStores: (params1, params2) => {
      dispatch(filterStores(params1, params2));
    },
    addStoretoVisited: (params) => {
      dispatch(addStoretoVisited(params));
    },
    getAddress: (params) => {
      dispatch(getAddress(params));
    },
    autocomplete: (params) => {
      dispatch(autocomplete(params));
    },
    getUserDetails: (params) => {
      dispatch(getUserDetails(params));
    },
    getActiveorderLength: (params) => {
      dispatch(getActiveorderLength(params));
    },
    getActiveOrders: (params) => {
      dispatch(getActiveOrders(params));
    },
    getCompletedOrders: (params) => {
      dispatch(getCompletedOrders(params));
    },
    getCart: (params) => {
      dispatch(getCart(params));
    },
    getDepartments: (params) => {
      dispatch(getDepartments(params));
    },
    getAvailableAddresses: (params) => {
      dispatch(getAvailableAddresses(params));
    },
    getCheckoutDetails: (params) => {
      dispatch(getCheckoutDetails(params));
    },
    getDeliveryCharge: (params) => {
      dispatch(getDeliveryCharge(params));
    },
    addtocartClick: (params1, params2, params3, params4, params5) => {
      dispatch(addtocart(params1, params2, params3, params4, params5));
    },
    updatecartClick: (params1, params2, params3, params4) => {
      dispatch(updatecart(params1, params2, params3, params4));
    },
    removeproductClick: (params1, params2, params3) => {
      dispatch(removeproduct(params1, params2, params3));
    },
    clearcart: () => {
      dispatch(clearcart());
    },
    clearstorefromcart: (params1, params2, params3, params4) => {
      dispatch(clearstorefromcart(params1, params2, params3, params4));
    },
    setCart: (params) => {
      dispatch(setCart(params));
    },
    getCategorylist: (params) => {
      dispatch(getCategorylist(params));
    },
    getCategory1list: (params) => {
      dispatch(getCategory1list(params));
    },
    getCategory2list: (params) => {
      dispatch(getCategory2list(params));
    },
    getDepartmentProducts: (params) => {
      dispatch(getDepartmentProducts(params));
    },
    getCategoryProducts: (params) => {
      dispatch(getCategoryProducts(params));
    },
    getCategory1Products: (params) => {
      dispatch(getCategory1Products(params));
    },
    getCategory2Products: (params) => {
      dispatch(getCategory2Products(params));
    },
    getProductDetails: (params) => {
      dispatch(getProductDetails(params));
    },
    selectCheckoutAddress: (params, params1, params2,params3,params4) => {
      dispatch(setCheckoutAddress(params, params1, params2,params3,params4));
    },
    addPlaceorderPhoneNumber: (params) => {
      dispatch(addPlaceorderPhoneNumber(params));
    },
    setCheckoutSlot: (params1, params2, params3) => {
      dispatch(setCheckoutSlot(params1, params2, params3));
    },
    getCheckoutPhoneNumber: (params) => {
      dispatch(getCheckoutPhoneNumber(params));
    },
    sendotp: (phonenumber, userid) => {
      dispatch(sendotp(phonenumber, userid));
    },
    verifyotp: (phonenumber, otp, userid) => {
      dispatch(verifyotp(phonenumber, otp, userid));
    },
    placeOrder: (params) => {
      dispatch(placeOrder(params));
    },
    getLiveOrders: (params) => {
      dispatch(getLiveOrders(params));
    },
    setUserLocation: (p1, p2, p3) => {
      dispatch(setUserLocation(p1, p2, p3));
    },
    updateprimarylocation: (p1, p2, p3, p4) => {
      dispatch(updateprimarylocation(p1, p2, p3, p4));
    },
    resetUpdate: () => {
      dispatch(resetUpdate());
    },
    suggestioncordinates: (params) => {
      dispatch(suggestioncordinates(params));
    },
    addAddress: (
      userID,
      housenumber,
      locationdescription,
      lattitude,
      longitude,
      landmark
    ) => {
      dispatch(
        addaddress(
          userID,
          housenumber,
          locationdescription,
          lattitude,
          longitude,
          landmark
        )
      );
    },
    EditAddress: (
      userID,
      id,
      housenumber,
      locationdescription,
      Oldhousenumber,
      oldLocation,
      Lattitude,
      Longitude,
      oldLat,
      oldLng,
      oldlandmark,
      landmark,
      type
    ) => {
      dispatch(
        changeaddress(
          userID,
          id,
          housenumber,
          locationdescription,
          Oldhousenumber,
          oldLocation,
          Lattitude,
          Longitude,
          oldLat,
          oldLng,
          oldlandmark,
          landmark,
          type
        )
      );
    },
    setAddresscordinates: (params1, params2) => {
      dispatch(setAddresscordinates(params1, params2));
    },
    clearAddresscordinates: () => {
      dispatch(clearAddresscordinates());
    },
    setEditAddressFields: (p1, p2, p3, p4, p5) => {
      dispatch(setEditAddressFields(p1, p2, p3, p4, p5));
    },
    clearAddAddressProps: () => {
      dispatch(clearAddAddressProps());
    },
    clearDeleteAddressProps: () => {
      dispatch(clearDeleteAddressProps());
    },
    clearEditAddressProps: () => {
      dispatch(clearEditAddressProps());
    },
    deleteaddress: (userID, id, lattitude, longitude) => {
      dispatch(deleteaddress(userID, id, lattitude, longitude));
    },
    EditAccount: (userID, firstname, lastname) => {
      dispatch(changeaccount(userID, firstname, lastname));
    },
    searchproducts: (params) => {
      dispatch(searchproducts(params));
    },
    Sendotp: (params) => {
      dispatch(Sendotp(params));
    },
    resetCheckoutProps: () => {
      dispatch(resetCheckoutProps());
    },
    ReferFriend: (params1, params2) => {
      dispatch(ReferFriend(params1, params2));
    },
    getOrderdetails: (orderid) => {
      dispatch(getOrderdetails(orderid));
    },
    deleteaccount: (p1, p2) => {
      dispatch(deleteaccount(p1, p2));
    },
    clearSigninProps: () => {
      dispatch(clearSigninProps());
    },
    getAvailableCouponsList: (params,params1) => {
      dispatch(getAvailableCouponsList(params,params1));
    },
    setCheckoutCoupons: (params,params1) => {
      dispatch(setCheckoutCoupons(params,params1));
    },
    applyCoupon: (couponId,storeId,totalPrice) => {
      dispatch(applyCoupon(couponId,storeId,totalPrice));
    },
    removeCoupon: (params) => {
      dispatch(removeCoupon(params));
    },
    getcomplaintslist: (params) => {
      dispatch(getcomplaintslist(params));
    },
    getPreviousComplaints: (params) => {
      dispatch(getPreviousComplaints(params))
    },
    getSubcomplaintslist: (params) => {
      dispatch(getSubcomplaintslist(params))
    },
    getOrderItemslist: (params) => {
      dispatch(getOrderItemslist(params))
    },
    SelectedItemList: (params) => {
      dispatch(SelectedItemList(params))
    },
    SubmitItemList: (params) => {
      dispatch(SubmitItemList(params))
    },
    selectItemImage: (params) => {
      dispatch(selectItemImage(params))
    },
    selectOption: (params1,params2) => {
      dispatch(selectOption(params1,params2))
    },
    RegisterComplaint: (p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11) => {
      dispatch(RegisterComplaint(p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11))
    },
    clearComplaintsProps: () => {
      dispatch(clearComplaintsProps())
    },
    resetComplaintProps: () => {
      dispatch(resetComplaintProps())
    },
    updatefullcart: (p1,p2,p3) => {
      dispatch(updatefullcart(p1,p2,p3))
    },
    upateAddress:(userid,storeid,addressId)=>{
      dispatch(upateAddress(userid,storeid,addressId))
    },
    adddeliveryTip:(userid,storeid,deliveryTip) =>{
      dispatch(adddeliveryTip(userid,storeid,deliveryTip))
    },
    ApplyBestCoupon:(storeid,userid) =>{
      dispatch(ApplyBestCoupon(storeid,userid))
    },
    addCookingInstructions: (params) => {
      dispatch(addCookingInstructions(params))
    },
    adddeliveryInstructions:(params) => {
      dispatch(adddeliveryInstructions(params))
    },
    quantityChanges :(p1, p2, p3,p4,p5,p6) =>{
      dispatch(quantityChanges(p1, p2, p3,p4,p5,p6))
    },

    setdateAndTimeForDelivery:(param1,param2) => {
      dispatch(setdateAndTimeForDelivery(param1,param2))
    },

    setPaymentType: (param1) => {
      dispatch(setPaymentType(param1))
    },
    getPopularProducts:(param1,param2) =>{
      dispatch(getPopularProducts(param1,param2))
    },
    serachProductsGlobally:(param1,param2) =>{
      dispatch( serachProductsGlobally(param1,param2))
    },
    getProductSearch:(param1,param2) =>{
      dispatch( getProductSearch(param1,param2))
    },
    removeAppliedCoupon:(param1,param2) =>{
      dispatch( removeAppliedCoupon(param1,param2))
    },
    updateRecentSearch:(param) =>{
      dispatch( updateRecentSearch(param))
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RouterFile);
