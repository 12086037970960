import React from 'react';
import { Box, Grid } from '@mui/material';
import ProductCard from '../../Components/ProductCard';

const CategoryProducts = (props) => {
    React.useEffect(() => {
          props.props.getCategoryProducts({storeid:localStorage.getItem('storeid'),
          categoryid:localStorage.getItem('categoryid'),
        userid:localStorage.getItem('userid')});
      }
        // eslint-disable-next-line
        , []);
    return (
        <Box flex={4} sx={{ paddingTop: '25px' }}>
            <Grid container spacing={2} sx={{flexGrow:1}}>
                {props.props.isStoreDetailsLoading && props.props.isStoreDetailsLoading === true ?
                        'Loading' :
                        (props.props.isStoreDetailsSuccess && props.props.isStoreDetailsSuccess === true &&
                            props.props.StoreDetails &&
                            props.props.StoreDetails !== null &&
                            props.props.StoreDetails.storeStatus === "active" && props.props.StoreDetails.storeWorking === true) ?
              (  props.props.isCategoryProductsLoading && props.props.isCategoryProductsLoading === true ?
                    'loading' :
                    props.props.isCategoryProducts && props.props.isCategoryProducts === true &&
                        props.props.CategoryProducts && props.props.CategoryProducts !== null && props.props.CategoryProducts.items&&
                        props.props.CategoryProducts.items.length > 0 ?
                        props.props.CategoryProducts.items.map((item) =>
                                    <Grid item style={{ paddingBottom: '25px' }}>
                                        <ProductCard
                                           id={item.id}
                                           productname={item.productname && item.productname}
                                           image={item.image && item.image.primary && item.image.primary}
                                           price={item.price && item.price}
                                           markedupProductPrice={item?.markedupProductPrice&&item?.markedupProductPrice}
                                           promoprice={item.promoprice && item.promoprice}
                                           storeid={localStorage.getItem('storeid')}
                                           storename={localStorage.getItem('storeName')}
                                           uom={item.uom && item.uom}
                                           quantity={item.quantity}
                                           bxgy={item.bxgy}
                                           storeoffline={false}
                                            {...props} />
                                    </Grid>
                        )
                        : props.props.isCategoryProductsFailed && props.props.isCategoryProductsFailed === true &&
                        'OOPS')
                        :
                        (
                            props.props.isCategoryProductsLoading && props.props.isCategoryProductsLoading === true ?
                    'loading' :
                    props.props.isCategoryProducts && props.props.isCategoryProducts === true &&
                        props.props.CategoryProducts && props.props.CategoryProducts !== null && props.props.CategoryProducts.items&&
                        props.props.CategoryProducts.items.length > 0 ?
                        props.props.CategoryProducts.items.map((item) =>
                                    <Grid item style={{ paddingBottom: '25px' }}>
                                        <ProductCard
                                           id={item.id}
                                           productname={item.productname && item.productname}
                                           image={item.image && item.image.primary && item.image.primary}
                                           price={item.price && item.price}
                                           markedupProductPrice={item?.markedupProductPrice&&item?.markedupProductPrice}
                                           promoprice={item.promoprice && item.promoprice}
                                           storeid={localStorage.getItem('storeid')}
                                           storename={localStorage.getItem('storeName')}
                                           uom={item.uom && item.uom}
                                           quantity={item.quantity}
                                           bxgy={item.bxgy}
                                           storeoffline={true}
                                            {...props} />
                                    </Grid>
                        )
                        : props.props.isCategoryProductsFailed && props.props.isCategoryProductsFailed === true &&
                        'OOPS'
                        )
                }
            </Grid>
        </Box>
    )
}

export default CategoryProducts