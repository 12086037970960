import React from 'react';
import { useStyles } from "./style.js";
import errorproduct from '../../Assets/Images/errorproduct.svg';
import AddButton from '../AddButton/index.js';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery, useTheme } from '@mui/material';
import ProductDetails from '../ProductDetails';
import './index.css';

const ProductCard = (props) => {
    
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <>
            <div className={classes.root}>
                <div 
                onClick={() => {
                    localStorage.setItem('productid', props.id);
                    
                    props?.props?.getProductDetails({
                        userid: localStorage.getItem('userid'),
                        storeid: localStorage.getItem('productstoreid') ? localStorage.getItem('productstoreid') : localStorage.getItem('storeid'),
                        id: props.id
                    })
                    filter:props?.props?.StoreDetails?.storeWorking === false?setOpen(false):setOpen(true);
                    
                }} style={{filter:props?.props?.StoreDetails?.storeWorking === false&&" grayscale(100%)"}}>
                    <div className={classes.discountcontainer}>
                        <div className={(props?.bxgy?.active!==null&&props?.bxgy?.active===true)?classes.discount:classes.emptydiscount}>
                            {(props?.bxgy?.active!==null&&props?.bxgy?.active===true)?
                            `BUY ${props.bxgy.xvalue} GET ${props.bxgy.yvalue} FREE`:null}
                        </div>
                    </div>
                    <div className={classes.imagecontainer}>
                        {(props.image == null ||
                            props.image === 'null' ||
                            props.image === undefined ||
                            props.image === 'undefined') ?
                            <img src={errorproduct} alt={`${props.productname}`} className={props.storeoffline===false?classes.image:classes.disabledimage} />
                            :
                            <img src={props.image} alt={`${props.productname}`} className={props.storeoffline===false?classes.image:classes.disabledimage} />
                        }
                    </div>
                    <div className={classes.productnamecontainer}>
                        <div className={'productname'}>
                            {props.productname}
                        </div>
                    </div>
                    <div className={classes.price}>
                        {((props.promoprice !== null) && (props.promoprice !== "") && (props.promoprice !== props.price)) ?
                            <>
                                <span className={classes.actualprice}>{'₹' +
                               ( (   (
                                     (
                                    (parseFloat(props.price) - parseFloat(props.promoprice)) /
                                parseFloat(props.price) *
                                100)* parseFloat(props?.markedupProductPrice))/100 )+ parseFloat(props?.markedupProductPrice))?.toFixed(2)}</span>
                                <span className={classes.sellingpricegreen}>
                                {'₹' + props.markedupProductPrice}
                                </span>
                            </>
                            :
                            <span className={classes.sellingpriceblack}>
                                {'₹' + props.markedupProductPrice}
                            </span>
                        }

                    </div> 
                        <div className={(props.promoprice && (props.promoprice !== null) && (props.promoprice !== "") && Math.round(
                            ((Number(props.price) - Number(props.promoprice)) /
                            Number(props.price)) *
                            100
                        ) > 0) ? classes.uom : classes.emptyuom}>
                        {((props.promoprice !== null) && (props.promoprice !== "") && (props.promoprice !== " ") && (props.promoprice !== props.price)) ? Math.round(
                                ((Number(props.price) - Number(props.promoprice)) /
                                Number(props.price)) *
                                100
                            ) + "% off" : ''}
                        </div>
                        <div style={{fontSize:"13px",fontFamily:"Roboto",marginTop:"8px"}}>
                           {props.quantity} {props.uom}
                        </div>
                    {/* } */}

                </div>
                {props.storeoffline===true?
                null
                :
                <div className={classes.addtocartbutton}>
                    <AddButton
                        storeid={props.storeid}
                        id={props.id}
                        image={props.image}
                        productname={props.productname}
                        price={props.markedupProductPrice}
                        markedupProductPrice={props?.markedupProductPrice}
                        promoprice={props.promoprice}
                        storename={props.storename}
                        uom={props.uom}
                        quantity={props.quantity}
                        {...props} />
                </div>}
            </div>
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: '10px',
                        backgroundColor: '#ffffff',
                        width: '100%',
                        height: '80%',
                    }
                }}
                fullScreen={fullScreen}
                maxWidth={'md'}
                scroll={'paper'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle className={classes.modalHeading}>
                    <span
                        style={{
                            width: '25px', float: 'right',
                            color: '#ffffff',
                            borderRadius: '50%',
                            fontSize: '14px',
                            height: '25px', background: '#BDBDBD', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
                        }}>
                        <CloseIcon
                            style={{
                                cursor: 'pointer',
                                fontSize: '14px'
                            }} onClick={handleClose} />
                    </span>
                </DialogTitle>
                <DialogContent>
                    <ProductDetails
                    storeoffline={props.storeoffline}
                        props={props}
                        {...props} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ProductCard
