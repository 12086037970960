import client from "../../Configurations/apollo";
import {
  ACTIVE_ORDERS,
  ACTIVE_ORDERS_LENGTH,
  ADD_ADDRESS,
  ADD_EMAIL,
  ADD_STORE_TO_FAVOURITES,
  ADD_TO_CART,
  UPDATE_CART_QUANTITY,
  AUTOCOMPLETE,
  BUSINESS_TYPES,
  CLEAR_CART,
  DELETE_ADDRESS,
  DELETE_STORE,
  EDIT_ACCOUNT,
  EDIT_ADDRESS,
  FINISHED_ORDERS,
  GET_ADDRESS,
  GET_AVAILABLE_ADDRESS,
  GET_CART,
  GET_CART_COUNT,
  GET_CATEGORY1_LIST,
  GET_CATEGORY1_PRODUCTS,
  GET_CATEGORY2_LIST,
  GET_CATEGORY2_PRODUCTS,
  GET_CATEGORY_LIST,
  GET_CATEGORY_PRODUCTS,
  GET_CHECKOUTCART,
  GET_DELIVERY_CHARGE,
  GET_DEPARTMENT_LIST,
  GET_DEPARTMENT_PRODUCTS,
  GET_MOBILEOTP,
  GET_PRODUCT_DETAILS,
  GET_STORES,
  GET_USER_DETAILS,
  GET_USER_PHONENUMBER,
  LIVE_ORDERS,
  ORDER,
  PLACE_ORDER,
  REFER_FRIEND,
  REMOVE_PRODUCT,
  SCHEDULE_ALERT,
  SEARCH,
  SEND_OTP,
  SUGGESTIONS_CORDINATES,
  UPDATE_CART,
  UPDATE_CART_AT_CHECKOUT,
  UPDATE_PASSWORD,
  UPDATE_PRIMARY_LOCATION,
  VERIFY_OTP,
  LIVE_ORDERS_LENGTH,
  ADD_RATING,
  UPDATE_FULL_CART,
  DELETE_ACCOUNT,
  AVAILABLE_COUPONS,
  APPLY_COUPON,
  REMOVE_COUPON,
  STORE_DETAILS,
  COMPLAINTS_LIST,
  PREVIOUS_COMPLAINTS,
  REGISTER_COMPLAINT,
  UPLOAD_IMAGE,
  ORDER_ITEMS_LIST,
  ADD_PASSWORD,
  updateCart2,
  DeliveryTip,
  applyBestCoupon,
  quantity_changes,
  GET_POPULAR_PRODUCT,
  SEARCH_PRODUCTS_GLOBALLY,
  GET_PRODUCT_SEARCH,
  REMOVE_APPLIED_COUPON,
  Update_Recent_Search
} from "./Queries";
import { ApolloClient } from "@apollo/client";
import { InMemoryCache } from "@apollo/client";

export const AlertClient = new ApolloClient({
  uri: `${process.env.REACT_APP_SCHEDULE_ALERT_URL}`,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});
//QUERIES
export const closesnackbars = () => {
  return (dispatch) => {
    dispatch({
      type: "CLOSE_SNACKBARS",
    });
  }
};

export const getStoreDetails = (params, params1) => {

  return (dispatch) => {
    dispatch({
      type: "STORE_DETAILS_LOADING",
    });
    client
      .query({
        query: STORE_DETAILS,
        variables: {
          storeid: params
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.store &&
            res.data.store !== null&&res.data.store.id)) {
            localStorage.setItem('storeid', res.data.store.id && res.data.store.id);
            localStorage.setItem('storeName', res.data.store.storeName && res.data.store.storeName);
            localStorage.setItem('storeLogo', res.data.store.storeLogoUrl && res.data.store.storeLogoUrl);
            localStorage.setItem('storelocation', res.data.store.storeLocation && res.data.store.storeLocation);
            // if (params1 === true) {
            //   localStorage.setItem('location', res.data.store.storeLocation && res.data.store.storeLocation);
            //   localStorage.setItem('lattitude', res.data.store.storeCoordinates && res.data.store.storeCoordinates.coordinates && res.data.store.storeCoordinates.coordinates[1]);
            //   localStorage.setItem('longitude', res.data.store.storeCoordinates && res.data.store.storeCoordinates.coordinates && res.data.store.storeCoordinates.coordinates[0]);
            // }
            dispatch({
              type: "STORE_DETAILS_SUCCESS",
              payload: res.data && res.data.store && res.data.store,
            });
          }
          else if (res.data && res.data.store &&
            res.data.store === null) {
            dispatch({
              type: "STORE_DETAILS_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "STORE_DETAILS_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "STORE_DETAILS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "STORE_DETAILS_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getStores = (params1, params2) => {

  return (dispatch) => {
    dispatch({
      type: "STORES_LOADING",
    });
    client
      .query({
        query: GET_STORES,
        variables: {
          lat: Number(params1),
          lng: Number(params2)
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.storelistdelivery &&
            res.data.storelistdelivery !== null)) {
            dispatch({
              type: "STORES_SUCCESS",
              payload: res.data && res.data.storelistdelivery && res.data.storelistdelivery,
            });
          }
          else if (res.data && res.data.storelistdelivery &&
            res.data.storelistdelivery === null) {
            dispatch({
              type: "STORES_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "STORES_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "STORES_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "STORES_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getBusinessTypes = () => {

  return (dispatch) => {
    dispatch({
      type: "BUSINESSTYPES_LOADING",
    });
    client
      .query({
        query: BUSINESS_TYPES,
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.businessType &&
            res.data.businessType !== null)) {
            dispatch({
              type: "BUSINESSTYPES_SUCCESS",
              payload: res.data && res.data.businessType && res.data.businessType,
            });
          }
          else if (res.data && res.data.businessType &&
            res.data.businessType === null) {
            dispatch({
              type: "BUSINESSTYPES_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "BUSINESSTYPES_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "BUSINESSTPES_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "BUSINESSTYPES_FAILED",
          payload: err.message,
        });
      });
  };
};
export const clearPasswordProps = () => {
  return async (dispatch) => {
    dispatch({ type: "CLEAR_PASSWORD_PROPS" });
  };
};
export const filterStores = (params1, params2) => {
  return (dispatch) => {
    dispatch({
      type: "FILTER_STORES",
      payload: { businessType: params1, stores: params2 }
    });
  }
};
export const getAddress = (params) => {

  return (dispatch) => {
    dispatch({
      type: "ADDRESSES_LOADING",
    });
    client
      .query({
        query: GET_ADDRESS,
        variables: {
          userid: params
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.userdetails &&
            res.data.userdetails !== null)) {
            dispatch({
              type: "ADDRESSES_SUCCESS",
              payload: res.data && res.data.userdetails && res.data.userdetails.address &&
                res.data.userdetails.address,
            });
          }
          else if (res.data && res.data.userdetails &&
            res.data.userdetails === null) {
            dispatch({
              type: "ADDRESSES_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "ADDRESSES_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "ADDRESSES_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ADDRESSES_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getUserDetails = (params) => {

  return (dispatch) => {
    dispatch({
      type: "ACCOUNT_LOADING",
    });
    client
      .query({
        query: GET_USER_DETAILS,
        variables: {
          userid: params
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.userdetails &&
            res.data.userdetails !== null)) {
            dispatch({
              type: "ACCOUNT_SUCCESS",
              payload: res.data && res.data.userdetails && res.data.userdetails &&
                res.data.userdetails,
            });
          }
          else if (res.data && res.data.userdetails &&
            res.data.userdetails === null) {
            dispatch({
              type: "ACCOUNT_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "ACCOUNT_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "ACCOUNT_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ACCOUNT_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getActiveOrders = (params) => {

  return (dispatch) => {
    dispatch({
      type: "ACTIVEORDERS_LOADING",
    });
    client
      .query({
        query: ACTIVE_ORDERS,
        variables: {
          userid: params
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.orderHistoryActive &&
            res.data.orderHistoryActive !== null)) {
            dispatch({
              type: "ACTIVEORDERS_SUCCESS",
              payload: res.data && res.data.orderHistoryActive && res.data.orderHistoryActive &&
                res.data.orderHistoryActive,
            });
          }
          else if (res.data && res.data.orderHistoryActive &&
            res.data.orderHistoryActive === null) {
            dispatch({
              type: "ACTIVEORDERS_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "ACTIVEORDERS_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "ACTIVEORDERS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ACTIVEORDERS_FAILED",
          payload: err.message,
        });
      });
  };
};
export const getActiveorderLength = (params) => {

  return (dispatch) => {
    dispatch({
      type: "ACTIVEORDERS_LENGTH_LOADING",
    });
    client
      .query({
        query: ACTIVE_ORDERS_LENGTH,
        variables: {
          userid: params
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.orderHistoryActive &&
            res.data.orderHistoryActive !== null)) {
            dispatch({
              type: "ACTIVEORDERS_LENGTH_SUCCESS",
              payload: res.data && res.data.orderHistoryActive && res.data.orderHistoryActive &&
                res.data.orderHistoryActive,
            });
          }
          else if (res.data && res.data.orderHistoryActive &&
            res.data.orderHistoryActive === null) {
            dispatch({
              type: "ACTIVEORDERS_LENGTH_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "ACTIVEORDERS_LENGTH_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "ACTIVEORDERS_LENGTH_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ACTIVEORDERS_LENGTH_FAILED",
          payload: err.message,
        });
      });
  };
};
export const getCompletedOrders = (params) => {

  return (dispatch) => {
    dispatch({
      type: "COMPLETEORDERS_LOADING",
    });
    client
      .query({
        query: FINISHED_ORDERS,
        variables: {
          userid: params.userid,
          pagination: {
            first: 10,
            offset: 0
          }
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.orderHistoryFinished &&
            res.data.orderHistoryFinished !== null)) {
            dispatch({
              type: "COMPLETEDORDERS_SUCCESS",
              payload: res.data && res.data.orderHistoryFinished &&
                res.data.orderHistoryFinished,
            });
          }
          else if (res.data && res.data.orderHistoryFinished &&
            res.data.orderHistoryFinished === null) {
            dispatch({
              type: "COMPLETEDORDERS_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "COMPLETEDORDERS_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "COMPLETEDORDERS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "COMPLETEDORDERS_FAILED",
          payload: err.message,
        });
      });
  };
};

//CART
export const getCart = (params) => {

  return (dispatch) => {
    dispatch({
      type: "CART_LOADING",
    });
    client
      .query({
        query: GET_CART,
        variables: {
          userid: params
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.cart &&
            res.data.cart !== null)) {
            dispatch({
              type: "CART_SUCCESS",
              payload: res.data && res.data.cart && res.data.cart &&
                res.data.cart,
            });
          }
          else if (res.data && res.data.cart &&
            res.data.cart === null) {
            dispatch({
              type: "CART_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "CART_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "CART_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CART_FAILED",
          payload: err.message,
        });
      });
  };
};

export const updatefullcart = (p1, p2, p3) => {

  return (dispatch) => {
    dispatch({
      type: "UPDATING_FULL_CART",
    });
    client
      .mutate({
        mutation: UPDATE_FULL_CART,
        variables: {
          userid: p1, 
          storeid: p2, 
          products: p3
        },
      })
      .then((res) => {
        
        if (res.data) {
          if ((res.data && res.data !== null && res.data.updateFullCart &&
            res.data.updateFullCart !== null)) {
            
            dispatch({
              type: "UPDATE_FULL_CART_SUCCESS",
              payload: res.data && res.data.updateFullCart && res.data.updateFullCart &&
                res.data.updateFullCart,
            });
          }
          else if (res.data && res.data.updateFullCart &&
            res.data.updateFullCart === null) {
            dispatch({
              type: "UPDATE_FULL_CART_FAILED",
              payload: res.errors[0].message,
            });
          }
          else if (res.errors) {
            dispatch({
              type: "UPDATE_FULL_CART_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "UPDATE_FULL_CART_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_FULL_CART_FAILED",
          payload: err.message,
        });
      });
  };
};

export const addCookingInstructions = (params)=>{
 return (dispatch) => {
    dispatch({
      type: "ADD_COOKING_INSTRUCTIONS",
      payload:params
    });
  }
};
export const deliveryTime = (params)=>{
  return (dispatch)=>{
    dispatch ({
      type:"SELECTED_TIME",
      palyod:params
    })
  }
}

export const adddeliveryInstructions = (params)=>{
  return (dispatch) => {
     dispatch({
       type: "ADD_DELIVERY_INSTRUCTIONS",
       payload:params
     });
   }
 };


 export const setdateAndTimeForDelivery = (param1,param2)=>{
  return (dispatch) => {
     dispatch({
       type: "SELECTED_DATE_AND_TIME_FOR_DELIVERY",
       payload: { selected_Date: param1, selected_Time: param2 }
     });
   }
 };

 export const setPaymentType = (param1)=>{
  return (dispatch) => {
     dispatch({
       type: "SELECTED_PAYMENT_TYPE",
       payload: { paymentType: param1 }
     });
   }
 };


export const upateAddress = (userid, storeid, addressId) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATING_ADDRESS_ID",
    });
    client
      .mutate({
        mutation: updateCart2,
        variables: {
          userid: userid,
          storeid: storeid,
          addressId: addressId,
        },
      })
      .then((res) => {
        if (res.data) {
          if (
            res.data &&
            res.data !== null &&
            res.data.updateCart2 &&
            res.data.updateCart2 !== null
          ) {
            dispatch({
              type: "UPDATE_ADDRESS_ID_SUCCESS",
              payload: res.data && res.data.updateCart2,
            });
          } else if (
            res.data &&
            res.data.updateCart2 &&
            res.data.updateCart2 === null
          ) {
            dispatch({
              type: "UPDATE_ADDRESS_ID_FAILED",
              payload: null,
            });
          } else if (res.errors) {
            dispatch({
              type: "UPDATE_ADDRESS_ID_FAILED",
              payload: res.errors[0].message,
            });
          }
        } else if (res.errors) {
          dispatch({
            type: "UPDATE_ADDRESS_ID_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_ADDRESS_ID_FAILED",
          payload: err.message,
        });
      });
  };
};

export const quantityChanges = (p1, p2, p3, p4, p5, p6) => {
  let storeid = p1;
  let userid = p2;
  let productid = p3;
  let  productprice = p4;
  let  quantity= p5;
  let price  = p6;
  return (dispatch) => {
    dispatch({
      type: "UPDATING_QUANTITY",
    });
    client
      .mutate({
        mutation: quantity_changes,
        variables: {
          storeid: storeid,
          userid: userid,
          product: {
            productid: `${productid}`,
            productPrice: parseFloat(productprice),
            quantity: Number(quantity),
            price: `${price}`,
            
          },
        },
      })
      .then((res) => {
        if (res.data) {
         
          if (
            res.data &&
            res.data !== null &&
            res.data.updateCart2 &&
            res.data.updateCart2 !== null
          ) {
            
            dispatch({
              type: "UPDATE_QUANTITY_SUCCESS",
              payload: res.data && res.data.updateCart2,
            });
          } else if (
            res.data &&
            res.data.updateCart2 &&
            res.data.updateCart2 === null
          ) {
            dispatch({
              type: "UPDATE_QUANTITY_FAILED",
              payload: null,
            });
          } else if (res.errors) {
            dispatch({
              type: "UPDATE_QUANTITY_FAILED",
              payload: res.errors[0].message,
            });
          }
        } else if (res.errors) {
          dispatch({
            type: "UPDATE_QUANTITY_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        console.error("Error in quantityChanges:", err);
        dispatch({
          type: "UPDATE_QUANTITY_FAILED",
          payload: err.message,
        });
      });
  };
};

export const adddeliveryTip = (userid, storeid, deliveryTip) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATING_DELIVERYTIP",
    });
    client
  .mutate({
    mutation: DeliveryTip,
    variables: {
      userid: userid,
      storeid: storeid,
      deliveryTip: deliveryTip,
    },
  })
  .then((res) => {
    if (
      res.data &&
      res.data !== null &&
      res.data.updateCart2 &&
      res.data.updateCart2 !== null
    ) {
      dispatch({
        type: "UPDATE_DELIVERYTIP_SUCCESS",
        payload: res.data && res.data.updateCart2,
      });
    } else if (
      res.data &&
      res.data.updateCart2 &&
      res.data.updateCart2 === null
    ) {
      dispatch({
        type: "UPDATE_DELIVERYTIP_FAILED",
        payload: null,
      });
    } else if (res.errors) {
      dispatch({
        type: "UPDATE_DELIVERYTIP_FAILED",
        payload: res.errors[0].message,
      });
    }
  })
  .catch((err) => {
    // ...
  });
  };
};

export const ApplyBestCoupon = (storeid, userid) => {
  
  return (dispatch) => {
    dispatch({
      type: "UPDATING_APPLYBEST_COUPON",
    });
    client
      .mutate({
        mutation: applyBestCoupon,
        variables: {
          storeid: storeid,
          userid: userid,
        },
      })
      .then((res) => {
        if (
          res.data &&
          res.data !== null &&
          res.data.applyBestCoupon &&
          res.data.applyBestCoupon !== null
        ) {
          dispatch({
            type: "UPDATE_APPLYBEST_COUPON_SUCCESS",
            payload: res.data && res.data.applyBestCoupon,
          });
        } else if (
          res.data &&
          res.data.applyBestCoupon &&
          res.data.applyBestCoupon === null
        ) {
          dispatch({
            type: "UPDATE_APPLYBEST_COUPON_FAILED",
            payload: null,
          });
        } else if (res.errors) {
          dispatch({
            type: "UPDATE_APPLYBEST_COUPON_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_APPLYBEST_COUPON_FAILED",
          payload: err.message,
        });
      });
  };
};

//CATEGORY_LISTING
export const getDepartments = (params) => {

  return (dispatch) => {
    dispatch({
      type: "DEPARTMENTS_LOADING",
    });
    client
      .query({
        query: GET_DEPARTMENT_LIST,
        variables: {
          storeid: params
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.salesdepartments &&
            res.data.salesdepartments !== null)) {
            dispatch({
              type: "DEPARTMENTS_SUCCESS",
              payload: res.data && res.data.salesdepartments && res.data.salesdepartments &&
                res.data.salesdepartments,
            });
          }
          else if (res.data && res.data.salesdepartments &&
            res.data.salesdepartments === null) {
            dispatch({
              type: "DEPARTMENTS_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "DEPARTMENTS_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "DEPARTMENTS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DEPARTMENTS_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getCategorylist = (params) => {

  return (dispatch) => {
    dispatch({
      type: "CATEGORIES_LOADING",
    });
    client
      .query({
        query: GET_CATEGORY_LIST,
        variables: {
          storeid: params.storeid,
          id: params.categoryid
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.salescategory &&
            res.data.salescategory !== null)) {
            dispatch({
              type: "CATEGORIES_SUCCESS",
              payload: res.data && res.data.salescategory && res.data.salescategory,
            });
          }
          else if (res.data && res.data.salescategory &&
            res.data.salescategory === null) {
            dispatch({
              type: "CATEGORIES_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "CATEGORIES_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "CATEGORIES_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CATEGORIES_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getCategory1list = (params) => {

  return (dispatch) => {
    dispatch({
      type: "CATEGORIES1_LOADING",
    });
    client
      .query({
        query: GET_CATEGORY1_LIST,
        variables: {
          storeid: params.storeid,
          id: params.categoryid
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.salescategory &&
            res.data.salescategory !== null)) {
            dispatch({
              type: "CATEGORIES1_SUCCESS",
              payload: res.data && res.data.salescategory && res.data.salescategory,
            });
          }
          else if (res.data && res.data.salescategory &&
            res.data.salescategory === null) {
            dispatch({
              type: "CATEGORIES1_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "CATEGORIES1_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "CATEGORIES1_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CATEGORIES1_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getCategory2list = (params) => {

  return (dispatch) => {
    dispatch({
      type: "CATEGORIES2_LOADING",
    });
    client
      .query({
        query: GET_CATEGORY2_LIST,
        variables: {
          storeid: params.storeid,
          id: params.categoryid
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.salescategory &&
            res.data.salescategory !== null)) {
            dispatch({
              type: "CATEGORIES2_SUCCESS",
              payload: res.data && res.data.salescategory && res.data.salescategory,
            });
          }
          else if (res.data && res.data.salescategory &&
            res.data.salescategory === null) {
            dispatch({
              type: "CATEGORIES2_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "CATEGORIES2_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "CATEGORIES1_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CATEGORIES1_FAILED",
          payload: err.message,
        });
      });
  };
};
// PRODUCT_LISTING

export const getDepartmentProducts = (params) => {

  return (dispatch) => {
    dispatch({
      type: "DEPARTMENT_PRODUCTS_LOADING",
    });
    client
      .query({
        query: GET_DEPARTMENT_PRODUCTS,
        variables: {
          departmentid: params.departmentid,
          pagination: { first: Number(50), offset: Number(0) }
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.salesdepartmentproductlist &&
            res.data.salesdepartmentproductlist !== null)) {
            dispatch({
              type: "DEPARTMENT_PRODUCTS_SUCCESS",
              payload: res.data && res.data.salesdepartmentproductlist && res.data.salesdepartmentproductlist &&
                res.data.salesdepartmentproductlist,
            });
          }
          else if (res.data && res.data.salesdepartmentproductlist &&
            res.data.salesdepartmentproductlist === null) {
            dispatch({
              type: "DEPARTMENT_PRODUCTS_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "DEPARTMENT_PRODUCTS_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "DEPARTMENT_PRODUCTS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DEPARTMENT_PRODUCTS_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getCategoryProducts = (params) => {

  return (dispatch) => {
    dispatch({
      type: "CATEGORY_PRODUCTS_LOADING",
    });
    client
      .query({
        query: GET_CATEGORY_PRODUCTS,
        variables: {
          categoryid: params.categoryid,
          pagination: { first: 50, offset: 0 }
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.salescategoryproductlist &&
            res.data.salescategoryproductlist !== null)) {
            dispatch({
              type: "CATEGORY_PRODUCTS_SUCCESS",
              payload: res.data && res.data.salescategoryproductlist && res.data.salescategoryproductlist,
            });
          }
          else if (res.data && res.data.salescategoryproductlist &&
            res.data.salescategoryproductlist === null) {
            dispatch({
              type: "CATEGORY_PRODUCTS_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "CATEGORY_PRODUCTS_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "CATEGORY_PRODUCTS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CATEGORY_PRODUCTS_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getCategory1Products = (params) => {

  return (dispatch) => {
    dispatch({
      type: "CATEGORY1_PRODUCTS_LOADING",
    });
    client
      .query({
        query: GET_CATEGORY1_PRODUCTS,
        variables: {
          categoryid1: params.categoryid1,
          pagination: { first: 50, offset: 0 }
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.salescategory1productlist &&
            res.data.salescategory1productlist !== null)) {
            dispatch({
              type: "CATEGORY1_PRODUCTS_SUCCESS",
              payload: res.data && res.data.salescategory1productlist && res.data.salescategory1productlist,
            });
          }
          else if (res.data && res.data.salescategory1productlist &&
            res.data.salescategory1productlist === null) {
            dispatch({
              type: "CATEGORY1_PRODUCTS_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "CATEGORY1_PRODUCTS_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "CATEGORY1_PRODUCTS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CATEGORY1_PRODUCTS_FAILED",
          payload: err.message,
        });
      });
  };
};
export const getCategory2Products = (params) => {

  return (dispatch) => {
    dispatch({
      type: "CATEGORY2_PRODUCTS_LOADING",
    });
    client
      .query({
        query: GET_CATEGORY2_PRODUCTS,
        variables: {
          categoryid2: params.categoryid2,
          pagination: { first: 50, offset: 0 },
          storeid: params.storeid
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.salescategory2productlist &&
            res.data.salescategory2productlist !== null)) {
            dispatch({
              type: "CATEGORY2_PRODUCTS_SUCCESS",
              payload: res.data && res.data.salescategory2productlist && res.data.salescategory2productlist,
            });
          }
          else if (res.data && res.data.salescategory2productlist &&
            res.data.salescategory2productlist === null) {
            dispatch({
              type: "CATEGORY2_PRODUCTS_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "CATEGORY2_PRODUCTS_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "CATEGORY2_PRODUCTS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CATEGORY2_PRODUCTS_FAILED",
          payload: err.message,
        });
      });
  };
};
export const getProductDetails = (params) => {

  return (dispatch) => {
    dispatch({
      type: "PRODUCT_DETAILS_LOADING",
    });
    client
      .query({
        query: GET_PRODUCT_DETAILS,
        variables: {
          id: params.id,
          storeid: params.storeid,
          userid: params.userid
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.salesproducts &&
            res.data.salesproducts !== null)) {
            dispatch({
              type: "PRODUCT_DETAILS_SUCCESS",
              payload: res.data && res.data.salesproducts && res.data.salesproducts,
            });
          }
          else if (res.data && res.data.salesproducts &&
            res.data.salesproducts === null) {
            dispatch({
              type: "PRODUCT_DETAILS_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "PRODUCT_DETAILS_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "PRODUCT_DETAILS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "PRODUCT_DETAILS_FAILED",
          payload: err.message,
        });
      });
  };
};
//CHECKOUT
export const getAvailableAddresses = (params) => {

  return (dispatch) => {
    dispatch({
      type: "AVAILABLEADDRESSES_LOADING",
    });
    client
      .query({
        query: GET_AVAILABLE_ADDRESS,
        variables: {
          userid: params
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.availableAddressUser &&
            res.data.availableAddressUser !== null)) {
            dispatch({
              type: "AVAILABLEADDRESSES_SUCCESS",
              payload: res.data && res.data.availableAddressUser && res.data.availableAddressUser &&
                res.data.availableAddressUser.availableAddressList && res.data.availableAddressUser.availableAddressList,
            });
          }
          else if (res.data && res.data.availableAddressUser &&
            res.data.availableAddressUser === null) {
            dispatch({
              type: "AVAILABLEADDRESSES_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "AVAILABLEADDRESSES_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "AVAILABLEADDRESSES_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "AVAILABLEADDRESSES_FAILED",
          payload: err.message,
        });
      });
  };
};
export const deleteaccount = (params1, params2) => {

  return (dispatch) => {
    dispatch({
      type: "DELETING_ACCOUNT",
    });
    client
      .mutate({
        mutation: DELETE_ACCOUNT,
        variables: {
          userid: `${params1}`,
          reason: `${params2}`
        },
      })
      .then((res) => {
        if (res.data) {
          localStorage.clear();
          localStorage.removeItem('location');
          localStorage.removeItem('token');
          localStorage.removeItem('userid');
          dispatch({
            type: "DELETE_ACCOUNT_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "DELETE_ACCOUNT_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DELETE_ACCOUNT_FAILED",
          payload: err.message,
        });
      });
  };
};

export const clearSigninProps = () => {

  return (dispatch) => {
    dispatch({
      type: "CLEAR_SIGNIN_PROPS",
    });
  };
};


export const getCheckoutDetails = (params) => {

  return (dispatch) => {
    dispatch({
      type: "CHECKOUT_DETAILS_LOADING",
    });
    client
      .query({
        query: GET_CHECKOUTCART,
        variables: {
          userid: params
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.cart &&
            res.data.cart !== null)) {
            dispatch({
              type: "CHECKOUT_DETAILS_SUCCESS",
              payload: res.data && res.data.cart && res.data.cart &&
                res.data.cart,
            });
          }
          else if (res.data && res.data.cart &&
            res.data.cart === null) {
            dispatch({
              type: "CHECKOUT_DETAILS_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "CHECKOUT_DETAILS_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "CHECKOUT_DETAILS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CHECKOUT_DETAILS_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getDeliveryCharge = (params) => {

  return (dispatch) => {
    dispatch({
      type: "DELIVERYCHARGE_LOADING",
    });
    client
      .query({
        query: GET_DELIVERY_CHARGE,
        variables: {
          storeid: params.storeid,
          lat: Number(params.lattitude),
          lng: Number(params.longitude)
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.deliveryCharge &&
            res.data.deliveryCharge !== null)) {
            dispatch({
              type: "DELIVERYCHARGE_SUCCESS",
              payload: res.data && res.data.deliveryCharge && res.data.deliveryCharge &&
                res.data.deliveryCharge,
            });
          }
          else if (res.data && res.data.deliveryCharge &&
            res.data.deliveryCharge === null) {
            dispatch({
              type: "DELIVERYCHARGE_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "DELIVERYCHARGE_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "DELIVERYCHARGE_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DELIVERYCHARGE_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getCheckoutPhoneNumber = (params) => {

  return (dispatch) => {
    dispatch({
      type: "CHECKOUT_PHONENUMBER_LOADING",
    });
    client
      .query({
        query: GET_USER_PHONENUMBER,
        variables: {
          userid: params
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.userdetails &&
            res.data.userdetails !== null)) {
            dispatch({
              type: "CHECKOUT_PHONENUMBER_SUCCESS",
              payload: res.data && res.data.userdetails && res.data.userdetails.phoneNumber &&
                res.data.userdetails.phoneNumber,
            });
          }
          else if (res.data && res.data.userdetails &&
            res.data.userdetails === null) {
            dispatch({
              type: "CHECKOUT_PHONENUMBER_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "CHECKOUT_PHONENUMBER_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "CHECKOUT_PHONENUMBER_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CHECKOUT_PHONENUMBER_FAILED",
          payload: err.message,
        });
      });
  };
};
//MUTATIONS

export const addStoretoVisited = (params) => {

  return (dispatch) => {
    dispatch({
      type: "ADDSTORETOVISITED_LOADING",
    });
    client
      .mutate({
        mutation: ADD_STORE_TO_FAVOURITES,
        variables: {
          storeid: `${params}`
        }
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data !== null && res.data.addStoreToUserInfo &&
            res.data.addStoreToUserInfo !== null)) {
            dispatch({
              type: "ADDSTORETOVISITED_SUCCESS",
              payload: res.data && res.data.addStoreToUserInfo && res.data.addStoreToUserInfo,
            });
          }
          else if (res.data && res.data.addStoreToUserInfo &&
            res.data.businessType === null) {
            dispatch({
              type: "ADDSTORETOVISITED_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "ADDSTORETOVISITED_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "ADDSTORETOVISITED_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ADDSTORETOVISITED_FAILED",
          payload: err.message,
        });
      });
  };
};

export const autocomplete = (params) => {
  return async (dispatch) => {
    dispatch({ type: "AUTOCOMPLETE_LOADING" })
    function onSuccess(success) {
      dispatch({ type: "AUTOCOMPLETE_SUCCESS", payload: success.data.googlemapautocomplete });
      return success;
    }
    function onError(error) {
      dispatch({ type: "AUTOCOMPLETE_FAILED", payload: error });
      return error;
    }
    function onNetworkError(error) {
      dispatch({ type: "AUTOCOMPLETE_ERROR" });
    }
    const success = await client.query({
      query: AUTOCOMPLETE,
      variables: {
        Input: params,
      },
    });
    if (success.data.googlemapautocomplete !== null) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};
export const sendotp = (phonenumber, userid) => {
  let phoneNumber = phonenumber
  let userID = userid

  return (dispatch) => {
    dispatch({
      type: "SENDING_OTP",
    });
    client
      .mutate({
        mutation: SEND_OTP,
        variables: {
          phoneNumber: `${phoneNumber}`,
          userid: `${userID}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.sendOTP && res.data.sendOTP !== null) {
          dispatch({
            type: "SEND_OTP_ORDER_SUCCESS",
            payload: res.data.sendOTP,
          });
        } else if (res.errors) {
          dispatch({
            type: "SEND_OTP_ORDER_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SEND_OTP_ORDER_FAILED",
          payload: err.message,
        });
      });
  };
};

export const verifyotp = (phonenumber, otp, userid) => {
  let phoneNumber = phonenumber
  let OTP = otp
  let USER_ID = userid
  return (dispatch) => {
    dispatch({
      type: "SENDING_OTP",
    });
    client
      .mutate({
        mutation: VERIFY_OTP,
        variables: {
          phoneNumber: `${phoneNumber}`,
          otp: Number(`${OTP}`),
          userid: `${USER_ID}`
        },
      })
      .then((res) => {
        if (res.data && res.data.verifyOTP && res.data.verifyOTP !== null) {
          dispatch({
            type: "VERIFY_OTP_ORDER_SUCCESS",
            payload: res.data.verifyOTP,
          });
        } else {
          dispatch({
            type: "VERIFY_OTP_ORDER_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "VERIFY_OTP_ORDER_FAILED",
          payload: err.message,
        });
      });
  };
};
//ADD_TO_CART
export const addtocart = (params1, params2, params3, params4, params5) => {
  let storeid = params1;
  let userid = params2;
  let productid = params3;
  let quantity = params4;
  return (dispatch) => {
    dispatch({
      type: "CART_WAITING",
    });
    client
      .mutate({
        mutation: ADD_TO_CART,
        variables: {
          storeid: `${storeid}`,
          userid: `${userid}`,
          products: {
            productid: `${productid}`,
            quantity: Number(quantity),
          },
        },
      })
      .then((res) => {
        if (res.data && res.data.createCart && res.data.createCart !== null) {
          localStorage.setItem(
            "cartCount",
            (
              parseInt(localStorage.getItem("cartCount")) + Number(quantity)
            ).toString()
          );
          dispatch({
            type: "CREATE_CART_SUCCESS",
            payload: res,
          });
        } else if (res.errors) {
          dispatch({
            type: "CREATE_CART_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CREATE_CART_FAILED",
          payload: err.message,
        });
      });
  };
};
export const updatecart = (params1, params2, params3, params4) => {
  let storeid = params1;
  let userid = params2;
  let productid = params3;
  let quantity = params4;
  return (dispatch) => {
    dispatch({
      type: "UPDATE_CART_WAITING",
    });
    client
      .mutate({
        mutation: UPDATE_CART,
        variables: {
          storeid: `${storeid}`,
          userid: `${userid}`,
          products: {
            productid: `${productid}`,
            quantity: Number(quantity),
          },
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "UPDATE_CART_SUCCESS",
            payload: res,
          });
        } else {
          dispatch({
            type: "UPDATE_CART_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_CART_FAILED",
          payload: err.message,
        });
      });
  };
};
export const removeproduct = (params1, params2, params3) => {
  let userid = params1;
  let productid = params2;
  let storeid = params3;
  return (dispatch) => {
    dispatch({
      type: "CART_WAITING",
    });
    client
      .mutate({
        mutation: REMOVE_PRODUCT,
        variables: {
          userid: `${userid}`,
          storeid: `${storeid}`,
          productid: `${productid}`,
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "REMOVE_PRODUCT_SUCCESS",
            payload: res,
          });
        } else {
          dispatch({
            type: "REMOVE_PRODUCT_FAILED",
            payload: res.mesSsage,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CART_FAILED",
          payload: err.message,
        });
      });
  };
};
export const getcartlist = (params1) => {
  return async (dispatch) => {
    dispatch({
      type: "CART_LIST_WAITING",
    });
    function onSuccess(success) {
      dispatch({ type: "CART_LIST_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "CART_LIST_FAILED", payload: error });
      return error;
    }
    function onNetworkError(error) {
      dispatch({ type: "CART_LIST_ERROR" });
    }
    const success = await client.query({
      query: GET_CART,
      variables: {
        userid: params1,
      },
    });
    if (success.data.cart !== null) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};
export const getcartcount = (params1) => {
  return async (dispatch) => {
    dispatch({
      type: "CART_COUNT_WAITING",
    });
    client
      .query({
        query: GET_CART_COUNT,
        variables: {
          userid: params1,
        },
        errorPolicy: "all",
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "CART_COUNT_SUCCESS",
            payload: res,
          });
        } else {
          dispatch({
            type: "CART_COUNT_FAILED",
            payload: res.error,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CART_COUNT_ERROR",
          payload: err.message,
        });
      });
  };
};

export const cartreset = () => {
  return (dispatch) => {
    dispatch({
      type: "CART_RESET",
    });
  };
};

export const setCart = (params) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_CART",
      payload: params,
    });
  };
};

export const updatecartatcheckout = () => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATING_CHECKOUT",
    });
    client
      .mutate({
        mutation: UPDATE_CART_AT_CHECKOUT,
        errorPolicy: "all",
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "UPDATE_CHECKOUT_SUCCESS",
            payload: res,
          });
        } else {
          dispatch({
            type: "UPDATE_CHECKOUT_FAILED",
            payload: res.error,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_CHECKOUT_ERROR",
          payload: err.message,
        });
      });
  };
};

export const createcartreset = () => {
  return (dispatch) => {
    dispatch({
      type: "CREATE_CART_RESET",
    });
  };
};

export const clearcart = () => {
  return async (dispatch) => {
    dispatch({
      type: "CLEARIING_CART",
    });
    client
      .mutate({
        mutation: CLEAR_CART,
        errorPolicy: "all",
      })
      .then((res) => {
        if (res.data && res.data.deleteAllStoresFromCart && res.data.deleteAllStoresFromCart !== null) {
          dispatch({
            type: "CLEAR_CART_SUCCESS",
            payload: res.data.deleteAllStoresFromCart,
          });
        } else if (res.errors) {
          dispatch({
            type: "CLEAR_CART_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CLEAR_CART_ERROR",
          payload: err.message,
        });
      });
  };
};

export const clearstorefromcart = (id, storeid, len, total) => {
  return async (dispatch) => {
    dispatch({
      type: "DELETING_STORE",
    });
    client
      .mutate({
        mutation: DELETE_STORE,
        variables: {
          storeid: `${storeid}`
        },
        errorPolicy: "all",
      })
      .then((res) => {
        if (res.data && res.data.deleteStoreFromCart && res.data.deleteStoreFromCart !== null) {
          dispatch({
            type: "DELETE_STORE_SUCCESS",
            payload: res.data.deleteStoreFromCart === "Deleted store successfully" && {
              leng: len,
              storeid: id,
              totalprice: total
            },
          });
        } else if (res.errors) {
          dispatch({
            type: "DELETE_STORE_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DELETE_STORE_ERROR",
          payload: err.message,
        });
      });
  };
};

export const setCheckoutAddress = (params, params1, params2, params3, params4) => {
  
  return (dispatch) => {
    ;
    dispatch({
      type: "CHECKOUT_ADDRESS_SELECTED",
      payload: {
        addressid: `${params}`,
        address: `${params1},${params2}`,
        addressLattitude: params3,
        addressLongitude: params4
      },
    });
  }
};
export const setCheckoutSlot = (params1, params2, params3) => {
  return (dispatch) => {
    dispatch({
      type: "CHECKOUT_SLOT_SELECTED",
      payload: {
        date: params1,
        time: params2,
        instructions: params3
      },
    });
  }
};
export const setCheckoutSlotinit = (params) => {
  return (dispatch) => {
    dispatch({
      type: "CHECKOUT_SLOT_SELECTED_INIT",
      payload: params
    });
  }
};
export const setCheckoutCoupons = (params, params1) => {
  return (dispatch) => {
    dispatch({
      type: "CHECKOUT_COUPONS",
      payload: {
        coupons: params,
        discounttoremove: params1
      },
    });
  }
};
export const setCheckoutDayIndex = (params1, params2) => {
  return (dispatch) => {
    dispatch({
      type: "CHECKOUT_SLOT_DAY_INDEX",
      payload: {
        dateindex: params1,
        dayindex: params2
      },
    });
  }
};

export const setCheckoutSlotDateSelection = (storeindex, keyword) => {
  return (dispatch) => {
    dispatch({
      type: "CHECKOUT_SLOT_DATE_SELECTION",
      payload: {
        Storeindex: storeindex,
        DateKeyword: keyword
      },
    });
  }
};

export const filterSelectedSlot = (storeindex) => {
  return (dispatch) => {
    dispatch({
      type: "FILTER_SLOT_DATE_SELECTION",
      payload: {
        Storeindex: storeindex,
      },
    });
  }
};


export const setCheckoutInstructions = (params) => {
  return (dispatch) => {
    dispatch({
      type: "CHECKOUT_INSTRUCTIONS",
      payload: params,
    });
  }
};

export const addPlaceorderPhoneNumber = (params) => {
  return (dispatch) => {
    dispatch({
      type: "GET_PHONENUMBER_SUCCESS",
      payload: { phoneNumber: params },
    });
  }
};

export const resetprops = () => {
  return (dispatch) => {
    dispatch({
      type: "RESETPROPS",
    });
  }
};
export const resetCheckoutProps = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_CHECKOUT_PROPS",
    });
  }
};

export const placeOrder = (params) => {
  return (dispatch) => {
    dispatch({
      type: "PLACE_ORDER_WAITING",
    });
    client
      .mutate({
        mutation: PLACE_ORDER,
        variables: {
          userid: localStorage.getItem('userid'),
          storeid: params.storeid,
          specialInstructions: params.specialInstructions,
          deliveryType: params.deliveryType,
          deliveryAddress: params.deliveryAddress,
          deliveryDate: params.deliveryDate,
          deliveryTime: params.deliveryTime,
          deliveryLat: params.deliveryLat,
          deliveryLng: params.deliveryLng,
          mobileNumber: params.mobileNumber,
          deliveryCharge: params.deliveryCharge,
          totalPriceDelivery: params.totalPriceDelivery,
          type: 2,
        },
      })
      .then((res) => {
        if (res.data && res.data.createOrder && res.data.createOrder !== null) {
          dispatch({
            type: "PLACE_ORDER_SUCCESS",
            payload: res.data.createOrder,
          });
          AlertClient.mutate({
            mutation: SCHEDULE_ALERT,
            variables: {
              storeid: res.data.createOrder.map(({ storeid }) => `${storeid.id}`),
              workingDays:
                res.data.createOrder.map(({ storeid }) => ({
                  openingTime: `${storeid.openingTime}`,
                  closingTime: `${storeid.closingTime}`,
                  holidays: storeid.holidays
                })
                )
              ,
              orderid: res.data.createOrder.map(i => i.id),
              orderNumber: res.data.createOrder.map(i => Number(i.orderNumber))
            }
          }).then((res) => {
            if (res.data && res.data.scheduleAlert && res.data.scheduleAlert !== null)
              console.log("ALERT_CLIE")
            else if (res.data && res.data.scheduleAlert && res.data.scheduleAlert == null)
              console.log("error")
            else if (res.errors)
              console.log("error")
          })
            .catch((err) => {
              console.log(err)
            })
        } else if (res.errors) {
          dispatch({
            type: "PLACE_ORDER_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "PLACE_ORDER_FAILED",
          payload: err.message,
        });
      });
  };
};
export const getLiveOrdersLength = (params1) => {
  return async (dispatch) => {
    dispatch({ type: "NOTIFICATIONS_LENGTH_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "NOTIFICATIONS_LENGTH_SUCCESS", payload: success.data.orderHistoryActive.length });
      return success;
    }
    function onError(error) {
      dispatch({ type: "NOTIFICATIONS_LENGTH_FAILED", payload: error });
      return error;
    }
    function onNetworkError(error) {
      dispatch({ type: "NOTIFICATIONS_LENGTH_ERROR" });
    }
    const success = await client.query({
      query: LIVE_ORDERS_LENGTH,
      variables: {
        userid: params1,
      },
    });
    if (success.data.orderHistoryActive !== null) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const getLiveOrders = (params1) => {
  return async (dispatch) => {
    dispatch({ type: "LIVE_ORDERS_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "LIVE_ORDERS_SUCCESS", payload: success.data.orderHistoryActive });
      return success;
    }
    function onError(error) {
      dispatch({ type: "LIVE_ORDERS_FAILED", payload: error });
      return error;
    }
    function onNetworkError(error) {
      dispatch({ type: "LIVE_ORDERS_ERROR" });
    }
    const success = await client.query({
      query: LIVE_ORDERS,
      variables: {
        userid: params1,
      },
    });
    if (success.data.orderHistoryActive !== null) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const setRating = (params) => {
  return {
    type: "SET_RATING",
    payload: {
      ...params.params,
      ratings: {
        rating: {
          ...params.params.ratings.rating,
          rating: params.e.target.value,
        },
      },
    },
  };
};

// ADD RATING

export const addrating = (orderID, Rating, areatoimprove, reviewcomments) => {
  // let userid = userID;
  // let newEmail = email.email;
  // let password = Password.password;

  return (dispatch) => {
    dispatch({
      type: "ADDING_RATING",
    });
    client
      .mutate({
        mutation: ADD_RATING,
        variables: {
          orderid: `${orderID}`,
          ratings: {
            rating: Number(Rating),
            areaToImprove: areatoimprove,
            reviewComments: `${reviewcomments}`,
          },
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "RATING_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "RATING_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "RATING_FAILED",
          payload: err.message,
        });
      });
  };
};
export function updateprimarylocation(params1, params2, params3, params4) {
  let userid = params1;
  let primaryLatitude = params2;
  let primaryLongitude = params3
  let primarylocation = params4

  return (dispatch) => {
    dispatch({
      type: "UPDATING_PRIMARYLOCATION",
    });
    client
      .mutate({
        mutation: UPDATE_PRIMARY_LOCATION,
        variables: {
          userid: `${userid}`,
          primaryLatitude: primaryLatitude,
          primaryLongitude: primaryLongitude,
          primaryLocation: `${primarylocation}`
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "UPDATE_PRIMARYLOCATION_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "UPDATE_PRIMARYLOCATION_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_PRIMARYLOCATION_FAILED",
          payload: err.message,
        });
      });
  };
}

export function resetUpdate() {
  return (dispatch) => {
    dispatch({
      type: "RESET_UPDATE",
    });
  }
}


export const suggestioncordinates = (params) => {
  return async (dispatch) => {
    function onSuccess(success) {
      dispatch({ type: "SUGGESTIONS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "SUGGESTIONS_FAILED", payload: error });
      return error;
    }
    function onNetworkError(error) {
      dispatch({ type: "SUGGESTIONS_ERROR" });
    }
    const success = await client.query({
      query: SUGGESTIONS_CORDINATES,
      variables: {
        placeid: params,
      },
    });
    if (success.data.googlemapplacedetails !== null) {
      // localStorage.setItem("lattitude",success.data.googlemapplacedetails.lat);
      // localStorage.setItem("longitude",success.data.googlemapplacedetails.lng);
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};
export function setUserLocation(p1, p2, p3) {
  return (dispatch) => {
    dispatch({
      type: "SET_USER_LOCATION",
      payload: {
        location: p1,
        lattitude: p2,
        longitude: p3
      }
    });
  }
}
export const addaddress = (
  userID,
  Housenumber,
  locationdescription,
  Lattitude,
  Longitude,
  Landmark
) => {
  let userid = userID;
  let houseNumber = Housenumber;
  let location = locationdescription;
  let lat = Lattitude;
  let lng = Longitude;
  let lndmrk = Landmark;

  return (dispatch) => {
    dispatch({
      type: "ADDING_ADDRESS",
    });
    client
      .mutate({
        mutation: ADD_ADDRESS,
        variables: {
          userid: `${userid}`,
          address: {
            type:'other',
            locationLat: Number(`${lat}`),
            locationLng: Number(`${lng}`),
            houseNumber: `${houseNumber}`,
            locationDescription: `${location}`,
            landmark:
              lndmrk !== "undefined" ||
                lndmrk !== "" ||
                lndmrk !== undefined ||
                lndmrk !== "null" ||
                lndmrk !== null
                ? `${lndmrk}`
                : "",
          },
        },
      })
      .then((res) => {
        if (res.data && res.data.updateUser && res.data.updateUser !== null) {
          dispatch({
            type: "ADD_ADDRESS_SUCCESS",
            payload: res.data.updateUser,
          });
          if (res.errors) {
            dispatch({
              type: "ADD_ADDRESS_FAILED",
              payload: res.errors[0].message,
            });
          }
        } else if (res.errors) {
          dispatch({
            type: "ADD_ADDRESS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ADD_ADDRESS_FAILED",
          payload: err.message,
        });
      });
  };
};
export const changeaddress = (
  userID,
  id,
  Housenumber,
  locationdescription,
  Oldhousenumber,
  oldlocation,
  Lattitude,
  Longitude,
  oldLat,
  oldLng,
  oldLandmark,
  Landmark
) => {
  let userid = userID;
  let houseNumber = Housenumber;
  let location = locationdescription;
  let oldhousenumber = Oldhousenumber;
  let oldLocation = oldlocation;
  let lat = Lattitude;
  let lng = Longitude;
  let oldlat = oldLat;
  let oldlng = oldLng;
  let oldlndmrk = oldLandmark;
  let lndmrk = Landmark;
  return (dispatch) => {
    dispatch({
      type: "CHANGING_ADDRESS",
    });
    client
      .mutate({
        mutation: EDIT_ADDRESS,
        variables: {
          userid: `${userid}`,
          address: {
            id: `${id}`,
            type:'other',
            locationLat: Number(`${oldlat}`),
            locationLng: Number(`${oldlng}`),
            houseNumber: `${oldhousenumber}`,
            locationDescription: `${oldLocation}`,
            landmark:
              !oldlndmrk === "undefined" ||
                oldlndmrk !== "" ||
                oldlndmrk !== undefined ||
                oldlndmrk !== "null" ||
                oldlndmrk !== null
                ? `${oldlndmrk}`
                : "",
          },
          newAddress: {
            type:'other',
            locationLat: Number(`${lat}`),
            locationLng: Number(`${lng}`),
            houseNumber: `${houseNumber}`,
            locationDescription: `${location}`,
            landmark:
              lndmrk !== "undefined" ||
                lndmrk !== "" ||
                lndmrk !== undefined ||
                lndmrk !== "null" ||
                lndmrk !== null
                ? `${lndmrk}`
                : "",
          },
        },
      })
      .then((res) => {
        if (res.data && res.data.updateUser && res.data.updateUser !== null) {
          dispatch({
            type: "ADDRESS_CHANGE_SUCCESS",
            payload: res.data.updateUser,
          });
          if (res.errors) {
            dispatch({
              type: "ADDRESS_CHANGE_FAILED",
              payload: res.errors[0].message,
            });
          }
        } else if (res.errors) {
          dispatch({
            type: "ADDRESS_CHANGE_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ADDRESS_CHANGE_FAILED",
          payload: err.message,
        });
      });
  };
};
export const updatepassword = (userID, Password, newpassword) => {
  let userid = userID;
  let password = Password;
  let newPassword = newpassword;

  return (dispatch) => {
    dispatch({
      type: "UPDATING_PASSWORD",
    });
    client
      .mutate({
        mutation: UPDATE_PASSWORD,
        variables: {
          userid: `${userid}`,
          password: `${password}`,
          newPassword: `${newPassword}`,
        },
      })
      .then((res) => {
        if (res.data) {
          if (res.data.updateUser !== null)
            dispatch({
              type: "UPDATE_PASSWORD_SUCCESS",
              payload: res.data.updateUser,
            });
          if (res.errors) {
            dispatch({
              type: "UPDATE_PASSWORD_FAILED",
              payload: res.errors[0].message,
            });
          }
        } else if (res.errors) {
          dispatch({
            type: "UPDATE_PASSWORD_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_PASSWORD_FAILED",
          payload: err.message,
        });
      });
  };
};

export const addpassword = (userID, newpassword) => {
  let userid = userID;
  let newPassword = newpassword;

  return (dispatch) => {
    dispatch({
      type: "UPDATING_PASSWORD",
    });
    client
      .mutate({
        mutation: ADD_PASSWORD,
        variables: {
          userid: `${userid}`,
          newPassword: `${newPassword}`,
        },
      })
      .then((res) => {
        if (res.data) {
          if (res.data.updateUser !== null)
            dispatch({
              type: "UPDATE_PASSWORD_SUCCESS",
              payload: res.data.updateUser,
            });
          if (res.errors) {
            dispatch({
              type: "UPDATE_PASSWORD_FAILED",
              payload: res.errors[0].message,
            });
          }
        } else if (res.errors) {
          dispatch({
            type: "UPDATE_PASSWORD_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_PASSWORD_FAILED",
          payload: err.message,
        });
      });
  };
};

export const setAddresscordinates = (params1, params2) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_ADDRESS_CORDINATES",
      payload: {
        params1,
        params2,
      },
    });
  };
};

export const clearAddresscordinates = () => {
  return async (dispatch) => {
    dispatch({ type: "CLEAR_ADRESS_PROPS" });
  };
};
export const setEditAddressFields = (p1, p2, p3, p4, p5) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_EDIT_ADRESS_PROPS",
      payload: { p1, p2, p3, p4, p5 },
    });
  };
};
export const clearAddAddressProps = () => {
  return async (dispatch) => {
    dispatch({ type: "CLEAR_ADDADRESS_PROPS" });
  };
};
export const clearDeleteAddressProps = () => {
  return async (dispatch) => {
    dispatch({ type: "DELETE_ADDADRESS_PROPS" });
  };
};
export const clearEditAddressProps = () => {
  return async (dispatch) => {
    dispatch({ type: "CLEAR_EDITADRESS_PROPS" });
  };
};

export const clearProductSearchProps = () => {
  return async (dispatch) => {
    dispatch({ type: "CLEAR_SEARCH_PRODUCTS" });
  };
};

export const removeLocationProps = () => {
  return (dispatch) =>{
    dispatch({ type: "REMOVE_LOCATION_PROPS" })
  };
};

export const deleteaddress = (userID, id, Lattitude, Longitude) => {
  let userid = userID;
  let lat = Lattitude;
  let lng = Longitude;

  return (dispatch) => {
    dispatch({
      type: "DELETING_ADDRESS",
    });
    client
      .mutate({
        mutation: DELETE_ADDRESS,
        variables: {
          userid: `${userid}`,
          address: {
            id: `${id}`,
            locationLat: Number(`${lat}`),
            locationLng: Number(`${lng}`),
          },
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "DELETE_ADDRESS_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "DELETE_ADDRESS_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DELETE_ADDRESS_FAILED",
          payload: err.message,
        });
      });
  };
};

export const changeaccount = (userID, Firstname, Lastname) => {
  let userid = userID;
  let firstname = Firstname;
  let lastname = Lastname;

  return (dispatch) => {
    dispatch({
      type: "CHANGING_ACCOUNT",
    });
    client
      .mutate({
        mutation: EDIT_ACCOUNT,
        variables: {
          userid: `${userid}`,
          firstName: `${firstname}`,
          lastName: `${lastname}`,
          // phoneNumber: `${phonenumber}`,
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "ACCOUNT_CHANGE_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "ACCOUNT_CHANGE_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ACCOUNT_CHANGE_FAILED",
          payload: err.message,
        });
      });
  };
};

export const searchproducts = (params) => {
  return (dispatch) => {
    dispatch({
      type: "SEARCHING_PRODUCTS",
    });
    client
      .query({
        query: SEARCH,
        variables: {
          searchKey: `${params.searchkey}`,
          storeid: `${params.storeid}`,
          from: 0,
          size: 10,
          userid: `${params.userid}`,
        },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.searchproducts &&
          res.data.searchproducts !== null
        ) {
          dispatch({
            type: "SEARCH_PRODUCTS_SUCCESS",
            payload: res.data.searchproducts
          });
        } else if (res.errors) {
          dispatch({
            type: "SEARCH_PRODUCTS_FAILED",
            payload: res.errors[0].message
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SEARCH_PRODUCTS_FAILED",
          payload: err
        });
      });
  }
};
export const Sendotp = (params) => {

  return (dispatch) => {
    dispatch({
      type: "SEND_OTP_WAITING",
    });
    client
      .mutate({
        mutation: GET_MOBILEOTP,
        variables: {
          phoneNumber: `${params}`,
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "SEND_OTP_SUCCESS",
            payload: res.data.sendOTP,
          });
          if (res.errors) {
            dispatch({
              type: "SEND_OTP_FAILED",
              payload: res.errors[0].message,
            })
          }
        } else if (res.errors) {
          dispatch({
            type: "SEND_OTP_FAILED",
            payload: res.errors,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SEND_OTP_FAILED",
          payload: err.message,
        });
      });
  };
}

export const ReferFriend = (p1, p2) => {

  return (dispatch) => {
    dispatch({
      type: "REFERING_FRIEND",
    });
    client
      .mutate({
        mutation: REFER_FRIEND,
        variables: {
          customerName: `${p1}`,
          email: `${p2}`
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "REFER_FRIEND_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "REFER_FRIEND_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "REFER_FRIEND_FAILED",
          payload: err.message,
        });
      });
  };
};

export const addemail = (userID, Email) => {
  let userid = userID;
  let email = Email;

  return (dispatch) => {
    dispatch({
      type: "ADDING_EMAIL",
    });
    client
      .mutate({
        mutation: ADD_EMAIL,
        variables: {
          userid: `${userid}`,
          newEmail: `${email}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.updateUser && res.data.updateUser !== null) {
          dispatch({
            type: "ADD_EMAIL_SUCCESS",
            payload: res.data.updateUser,
          });
        } else if (res.errors) {
          dispatch({
            type: "ADD_EMAIL_FAILED",
            payload: res.errors[0].message,
          });
        }
        else {
          dispatch({
            type: "ADD_EMAIL_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ADD_EMAIL_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getOrderdetails = (params1) => {
  localStorage.setItem('Orderid', params1)
  return async (dispatch) => {
    dispatch({ type: "ORDERDETAILS_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "ORDERDETAILS_SUCCESS", payload: success.data.orderdetails });
      return success;
    }
    function onError(error) {
      dispatch({ type: "ORDERDETAILS_FAILED", payload: error });
      return error;
    }
    function onNetworkError(error) {
      dispatch({ type: "ORDERDETAILS_ERROR" });
    }
    const success = await client.query({
      query: ORDER,
      variables: {
        orderid: params1,
      },
    });
    if (success.data.orderdetails !== null) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const updatecartproduct = (params1, params2, params3, params4) => {
  let storeid = params1;
  // let userid = params2;
  let productid = params3;
  let quantity = params4;
  return (dispatch) => {
    dispatch({
      type: "CART_WAITING",
    });
    client
      .mutate({
        mutation: UPDATE_CART_QUANTITY,
        variables: {
          storeid: `${storeid}`,
          // userid: `${userid}`,
          products: {
            productid: `${productid}`,
            quantity: Number(quantity),
          },
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "UPDATE_CART_QUANTITY_SUCCESS",
            payload: res,
          });
        } else {
          dispatch({
            type: "UPDATE_CART_QUANTITY_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_CART_QUANTITY_FAILED",
          payload: err.message,
        });
      });
  };
};

// COUPONS

export const getAvailableCouponsList = (params,params1) => {
  return (dispatch) => {
    dispatch({
      type: "LOADING_AVAILABLE_COUPONS",
    });
    client
      .mutate({
        mutation: AVAILABLE_COUPONS,
        variables: {
          storeIds: params,
          userId:params1,
        },
      })
      .then((res) => {
       
        if (res.data && res.data.availableCouponsList && res.data.availableCouponsList !== null) {
          dispatch({
            type: "AVAILABLE_COUPONS_SUCCESS",
            payload: res.data.availableCouponsList,
          });
        } else {
          dispatch({
            type: "AVAILABLE_COUPONS_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "AVAILABLE_COUPONS_FAILED",
          payload: err.message,
        });
      });
  };
};

export const applyCoupon = (couponId,storeId,totalPrice) => {
  return (dispatch) => {
    dispatch({
      type: "APPLYING_COUPON",
    });
    client
      .mutate({
        mutation: APPLY_COUPON,
        variables: {
          couponId: couponId,
          storeId: storeId,
          totalPrice: totalPrice,
        },
      })
      .then((res) => {
        if (res.data && res.data.applyCoupon && res.data.applyCoupon !== null) {
          dispatch({
            type: "APPLY_COUPON_SUCCESS",
            payload: res.data.applyCoupon,
          });
        } else {
          dispatch({
            type: "APPLY_COUPON_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "APPLY_COUPON_FAILED",
          payload: err.message,
        });
      });
  };
};

export const removeCoupon = (params) => {
  return (dispatch) => {
    dispatch({
      type: "REMOVING_COUPON",
    });
    client
      .mutate({
        mutation: REMOVE_COUPON,
        variables: {
          storeId: params,
        },
      })
      .then((res) => {
        if (res.data && res.data.removeCoupon && res.data.removeCoupon !== null) {
          dispatch({
            type: "REMOVE_COUPON_SUCCESS",
            payload: res.data.removeCoupon
          });
        } else {
          dispatch({
            type: "REMOVE_COUPON_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "REMOVE_COUPON_FAILED",
          payload: err.message,
        });
      });
  };
};

export const removeAppliedCoupon =(param1,param2) =>{
  
  return (dispatch) => {
    dispatch({
      type: "REMOVING_APPLIED_COUPON",
    });
    client
      .mutate({
        mutation: REMOVE_APPLIED_COUPON,
        variables: {
          storeId: param1,
          userid:param2
        },
      })
      .then((res) => {
        
        if (res.data && res.data.removeCoupon && res.data.removeCoupon !== null) {
          dispatch({
            type: "REMOVE_APPLIED_COUPON_SUCCESS",
            payload: res.data.removeCoupon
          });
        } else {
          dispatch({
            type: "REMOVE_APPLIED_COUPON_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "REMOVE_APPLIED_COUPON_FAILED",
          payload: err.message,
        });
      });
  };
}

export const otpclose = () => {
  return async (dispatch) => {
    dispatch({
      type: "RESET_OTP_PROPS",
    });
  };
};

export const getcomplaintslist = (params1) => {
  return async (dispatch) => {
    function onSuccess(success) {
      dispatch({ type: "COMPLAINTS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "COMPLAINTS_FAILED", payload: error });
      return error;
    }
    function onNetworkError(error) {
      dispatch({ type: "COMPLAINTS_ERROR" });
    }
    const success = await client.query({
      query: COMPLAINTS_LIST,
      variables: {
        parentid: Number(params1),
      },
    });
    if (success.data.complaintsBot !== null) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const getSubcomplaintslist = (params1) => {
  return async (dispatch) => {
    function onSuccess(success) {
      dispatch({ type: "SUB_COMPLAINTS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "SUB_COMPLAINTS_FAILED", payload: error });
      return error;
    }
    function onNetworkError(error) {
      dispatch({ type: "SUB_COMPLAINTS_ERROR" });
    }
    const success = await client.query({
      query: COMPLAINTS_LIST,
      variables: {
        parentid: Number(params1),
      },
    });
    if (success.data.complaintsBot !== null) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const getPreviousComplaints = (params1) => {
  return async (dispatch) => {
    dispatch({ type: "PREVIOUS_COMPLAINTS_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "PREVIOUS_COMPLAINTS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "PREVIOUS_COMPLAINTS_FAILED", payload: error });
      return error;
    }
    function onNetworkError(error) {
      dispatch({ type: "PREVIOUS_COMPLAINTS_ERROR" });
    }
    const success = await client.query({
      query: PREVIOUS_COMPLAINTS,
      variables: {
        orderid: params1,
      },
    });
    if (success.data.orderComplaints !== null) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const clearComplaints = (id) => {
  return async (dispatch) => {
    dispatch({ type: "CLEAR_COMPLAINTS", payload: id });
  }
}

export const clearComplaintsProps = () => {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_COMPLAINT_PROPS",
    })
  }
}

export const getOrderItemslist = (params1) => {
  return async (dispatch) => {
    dispatch({ type: "ORDER_ITEMS_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "ORDER_ITEMS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "ORDER_ITEMS_FAILED", payload: error });
      return error;
    }
    function onNetworkError(error) {
      dispatch({ type: "ORDER_ITEMS_ERROR" });
    }
    const success = await client.query({
      query: ORDER_ITEMS_LIST,
      variables: {
        orderid: params1,
      },
    });
    if (success.data.orderdetails !== null) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const uploadImage = (p1, p2, p3) => {

  return (dispatch) => {
    dispatch({
      type: "UPLOADING_IMAGE",
    });
    client
      .mutate({
        mutation: UPLOAD_IMAGE,
        variables: {
          file: p1.files[0],
          type: 4,
          orderid: `${p3}`
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "UPLOAD_IMAGE_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "UPLOAD_IMAGE_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPLOAD_IMAGE_FAILED",
          payload: err.message,
        });
      });
  };
};

export const resetComplaintProps = () => {

  return (dispatch) => {
    dispatch({
      type: "RESET_COMPLAINT_PROPS",
    });
  }
};

export const RegisterComplaint = (p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11) => {

  return (dispatch) => {
    dispatch({
      type: "REGISTERING_COMPLAINT",
    });
    client
      .mutate({
        mutation: REGISTER_COMPLAINT,
        variables: {
          orderid: `${p1}`,
          userid: `${p2}`,
          storeid: `${p3}`,
          userMessage: p4,
          image: p5,
          itemList: p6,
          refund: p7,
          refundAmount: Number(p8),
          refundStatus: `${p9}`,
          replacement: p10,
          replacementStatus: `${p11}`
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "REGISTER_COMPLAINT_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "REGISTER_COMPLAINT_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "REGISTER_COMPLAINT_FAILED",
          payload: err.message,
        });
      });
  };
};

export const selectComplaint = (params1, params2, params3, params4, params5, params6, params7, params8) => {
  return async (dispatch) => {
    dispatch({
      type: "SELECT_COMPLAINT",
      payload: {
        id: params1,
        parentid: params2,
        message: params3,
        imageUpload: params4,
        itemsList: params5,
        quantity: params6,
        refund: params7,
        replacement: params8
      }
    });
  }
}

export const SelectedItemList = (p) => {
  return async (dispatch) => {
    dispatch({
      type: "SELECTED_ITEMS_LIST",
      payload: p
    })
  }
}

export const SubmitItemList = (p) => {
  return async (dispatch) => {
    dispatch({
      type: "SUBMIT_ITEMS_LIST",
      payload: p
    })
  }
}

export const selectItemImage = (p) => {
  return async (dispatch) => {
    dispatch({
      type: "SELECTED_ITEMS_IMAGE",
      payload: p
    })
  }
}

export const selectOption = (p1, p2) => {
  return async (dispatch) => {
    dispatch({
      type: "SELECTED_OPTION",
      payload: {
        refund: p1,
        replacement: p2
      }
    })
  }
}

export const getPopularProducts = (params, params1) => {

  return (dispatch) => {
    dispatch({
      type: "POPULAR_PRODUCTS_LOADING",
    });
    client
      .query({
        query: GET_POPULAR_PRODUCT,
        variables: {
          storeid: params
        },
      })
      .then((res) => {
        if (res.data) {
          if ((res.data && res.data)) {
            
            dispatch({
              type: "GET_POPULAR_PRODUCTS_SUCCESS",
              payload: res.data.findPopularProducts
            });
          }
          else if (res.data && res.data.store &&
            res.data.store === null) {
            dispatch({
              type: "GET_POPULAR_PRODUCTS_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "GET_POPULAR_PRODUCTS_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "GET_POPULAR_PRODUCTS_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_POPULAR_PRODUCTS_FAILED",
          payload: err.message,
        });
      });
  };
};

export const serachProductsGlobally = (params1, params2,) => {

  return (dispatch) => {
    dispatch({
      type: "SEARCH_PRODUCTS_GLOBALLY_LOADING",
    });
    client
      .query({
        query: SEARCH_PRODUCTS_GLOBALLY,
        variables: {
          pagination: {
            first: 10,
            offset: 0
          },
          storeIds: params1,
          
          searchKey:params2
        },
      })
      .then((res) => {
        
        if (res.data) {
          if ((res.data && res.data.searchProductsGlobally !== null)) {
            
            dispatch({
              type: "SEARCH_PRODUCTS_GLOBALLY_SUCCESS",
              payload: res.data.searchProductsGlobally
            });
          }
          else if (res.data &&
            res.data.store === null) {
            dispatch({
              type: "SEARCH_PRODUCTS_GLOBALLY_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "SEARCH_PRODUCTS_GLOBALLY_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "SEARCH_PRODUCTS_GLOBALLY_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SEARCH_PRODUCTS_GLOBALLY_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getProductSearch = (params1, params2) => {

  return (dispatch) => {
    dispatch({
      type: "GET_PRODUCT_SEARCH_LOADING",
    });
    client
      .query({
        query: GET_PRODUCT_SEARCH,
        variables: {
          pagination: {
            first: 10,
            offset: 0
          },
          storeIds: params1,
          
          searchKey:params2
        },
      })
      .then((res) => {
        
        if (res.data) {
          if ((res.data && res.data.getSearchResults !== null)) {
            
            dispatch({
              type: "GET_PRODUCT_SEARCH_SUCCESS",
              payload: res.data.getSearchResults
            });
          }
          else if (res.data &&
            res.data.store === null) {
            dispatch({
              type: "GET_PRODUCT_SEARCH_FAILED",
              payload: null
            });
          }
          else if (res.errors) {
            dispatch({
              type: "GET_PRODUCT_SEARCH_FAILED",
              payload: res.errors[0].message,
            });
          }

        }
        else if (res.errors) {
          dispatch({
            type: "GET_PRODUCT_SEARCH_FAILED",
            payload: res.errors[0].message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SEARCH_PRODUCTS_GLOBALLY_FAILED",
          payload: err.message,
        });
      });
  };
};

export const updateRecentSearch = (param) => {

  return (dispatch) => {
    dispatch({
      type: "UPDATING_RECENT_SEARCH",
    });
    client
      .mutate({
        mutation: Update_Recent_Search,
        variables: {
          keyword:param
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "RECENT_SEARCH_SUCCESS",
            payload: res.data.updateRecentSearchesOfUser,
          });
        } else {
          dispatch({
            type: "RECENT_SEARCH_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "RECENT_SEARCH_FAILED",
          payload: err.message,
        });
      });
  };
};