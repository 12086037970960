import React from 'react';
import { Divider } from '@mui/material';
import './Coupon.css';
import tick from '../../../Assets/Images/tick.jpg'

const CouponPopup = ({ props, setOpen, open, setState }) => {


    const couponCode = props?.Cart?.couponId?.couponCode || ''; // Default to empty string
    const discountAmt = props?.Cart?.couponId?.disAmt || ''; // Default to empty string

    const handleClose = () => {
        setOpen(false)
        setState({ right: false })
    };



    return (

        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={tick} alt='tick' height="40px" style={{ width: "40px" }} />
            </div>
            <div className='applied-coupon-name'>
                {`${couponCode} applied`}
            </div>
            <div className='applied-coupon-savings'>
                {`You Saved ${discountAmt}`}
            </div>
            <div className='applied-coupon-text'>
                {'With This Coupon Code '}
            </div>
            <Divider className='dashline' />
            <div className='thanks-text' onClick={handleClose}>
                {'Thanks'}
            </div>
        </div>
    );
};

export default CouponPopup;
